import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import {
  ProgramInvoiceDataFragment,
  useProgramInvoicesForProgramQuery,
  useSendInvoiceReminderMutation,
  useVoidInvoiceMutation,
} from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';
import { defaultTransitionProps } from '../../../lib/animation';
import ToastAlert from '../../../components/ToastAlert';

import { usePatientProfileContext } from '..';

import EmptyProfileTab from '../../../components/EmptyProfileTab';
import { useAuth } from '../../../../contexts/AuthContext';
import IllustrationBanknotes from '../../../svgs/IllustrationBanknotes';
import InvoiceTable from '../../Payments/Table';
import { ColumnOrder } from '../../../types/tables';
import { sortInvoices, sortOptions } from '../../Payments/helpers';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { PlusSmIcon } from '@heroicons/react/outline';

const Invoices = () => {
  const navigate = useNavigate();
  const { patient } = usePatientProfileContext();

  const { authedProviderUser } = useAuth();
  const hasStripeIntegrationSetup =
    authedProviderUser?.provider.stripeAccountId;

  const {
    data: programInvoicesData,
    loading: programInvoicesLoading,
    error: programInvoicesError,
    refetch: refetchProgramInvoices,
  } = useProgramInvoicesForProgramQuery({
    variables: {
      programId: patient.programInstanceId,
    },
    skip: !hasStripeIntegrationSetup,
  });

  useEffect(() => {
    if (programInvoicesError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch invoices."
          level="error"
        />
      ));
    }
  }, [programInvoicesError]);

  const [sendInvoiceReminder] = useSendInvoiceReminderMutation();
  const [voidInvoice] = useVoidInvoiceMutation();

  const [actionLoadingInvoiceId, setActionLoadingInvoiceId] = useState<
    string | null
  >(null);

  const [invoiceOrder, setInvoiceOrder] = useState<ColumnOrder>(
    sortOptions.newestCreated,
  );

  const allProgramInvoices = programInvoicesData?.programInvoices || [];

  const sortedProgramInvoices = sortInvoices(allProgramInvoices, invoiceOrder);

  const handleSendInvoiceReminder = async (
    programInvoice: ProgramInvoiceDataFragment,
  ) => {
    try {
      const { data } = await sendInvoiceReminder({
        variables: {
          programInvoiceId: programInvoice.id,
        },
      });
      if (data?.sendInvoiceReminder) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully sent an invoice reminder to ${programInvoice.patient?.name}`}
          />
        ));
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    }
  };

  const handleVoidInvoice = async (
    programInvoice: ProgramInvoiceDataFragment,
  ) => {
    try {
      setActionLoadingInvoiceId(programInvoice.id);
      const { data } = await voidInvoice({
        variables: {
          programInvoiceId: programInvoice.id,
        },
      });
      if (data?.voidInvoice) {
        refetchProgramInvoices();
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            level="success"
            message={`Successfully voided invoice for ${programInvoice.patient?.name}`}
          />
        ));
      }
    } catch (err) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Something went wrong."
          level="error"
        />
      ));
    } finally {
      setActionLoadingInvoiceId(null);
    }
  };

  const navigateToSendInvoice = () => {
    navigate(`/payments/new`, {
      state: { programId: patient.programInstanceId },
    });
  };

  return (
    <>
      <Transition
        show={Boolean(
          hasStripeIntegrationSetup &&
            allProgramInvoices &&
            !programInvoicesError &&
            !programInvoicesLoading,
        )}
        {...defaultTransitionProps}
        className="w-full pt-5"
      >
        {allProgramInvoices.length ? (
          <div className="flex flex-col items-start justify-start">
            <Button
              title="Send invoice"
              size="small"
              iconPosition="left"
              IconComponent={PlusSmIcon}
              onClick={navigateToSendInvoice}
              className="mb-3"
            />
            <InvoiceTable
              hasAnyProgramInvoices={Boolean(allProgramInvoices.length)}
              programInvoices={sortedProgramInvoices}
              programInvoicesLoading={programInvoicesLoading}
              onClickSendReminder={handleSendInvoiceReminder}
              onClickVoidInvoice={handleVoidInvoice}
              invoiceOrder={invoiceOrder}
              setInvoiceOrder={setInvoiceOrder}
              actionLoadingInvoiceId={actionLoadingInvoiceId}
            />
          </div>
        ) : (
          <EmptyProfileTab
            IconComponent={IllustrationBanknotes}
            iconClassName={'text-green-125'}
            titleText={`${patient.firstName}'s invoices`}
            subTitleText={`All the invoices you send to ${patient.firstName} through Homecoming will show up here.`}
            onClickButton={navigateToSendInvoice}
            buttonTitle={'Send an invoice'}
          />
        )}
      </Transition>

      {programInvoicesLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default Invoices;

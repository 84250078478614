import {
  AssessmentAnswer,
  AssessmentQuestionType,
  MultipleChoiceScoredAssessmentAnswer,
  MultipleChoiceAssessmentAnswer,
  MultiSelectAssessmentAnswer,
  FreeTextAssessmentAnswer,
  RichTextAssessmentAnswer,
  SignatureAssessmentAnswer,
  MultiSignatureAssessmentAnswer,
} from '../../../../generated/graphql';

export const findAnswerForQuestion = (
  answers: AssessmentAnswer[],
  question: { question: string; questionType: AssessmentQuestionType },
): AssessmentAnswer | undefined => {
  return answers.find((ans) => {
    switch (ans.questionType) {
      case AssessmentQuestionType.MultipleChoiceScored:
        return (
          (ans as MultipleChoiceScoredAssessmentAnswer).question ===
          question.question
        );
      case AssessmentQuestionType.MultipleChoice:
        return (
          (ans as MultipleChoiceAssessmentAnswer).question === question.question
        );
      case AssessmentQuestionType.MultiSelect:
        return (
          (ans as MultiSelectAssessmentAnswer).question === question.question
        );
      case AssessmentQuestionType.FreeText:
        return (ans as FreeTextAssessmentAnswer).question === question.question;
      case AssessmentQuestionType.RichText:
        return (ans as RichTextAssessmentAnswer).question === question.question;
      case AssessmentQuestionType.Initials:
        return (
          (ans as SignatureAssessmentAnswer).question === question.question
        );
      case AssessmentQuestionType.Signature:
        return (
          (ans as SignatureAssessmentAnswer).question === question.question
        );
      case AssessmentQuestionType.MultiSignature:
        return (
          (ans as MultiSignatureAssessmentAnswer).question === question.question
        );
      default:
        return false;
    }
  });
};

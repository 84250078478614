import { FC } from 'react';
import {
  AssessmentType,
  LatestCompletedProgramAssessmentsQuery,
} from '../../../../../generated/graphql';
import ContentPreview from '../../../../components/ContentPreview';
import { AssessmentCompletionDataTypename } from '../../../../lib/assessments';

import { getTimeStamp } from '../../../../lib/time';
import LatestAssessmentCard from './LatestAssessmentCard';
import { ContentType } from '../../../../lib/followUp';

const LatestAssessmentCards: FC<{
  latestCompletedProgramAssessments: LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'];
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<
      | LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number]['assessment']
      | undefined
    >
  >;
  setSelectedProgramAssessmentId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setIsAssessmentsCompletedSlideoverOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsAssessmentCompletedSlideoverOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}> = ({
  latestCompletedProgramAssessments,
  setSelectedAssessment,
  setSelectedProgramAssessmentId,
  setIsAssessmentsCompletedSlideoverOpen,
  setIsAssessmentCompletedSlideoverOpen,
}) => {
  const setAssessmentForSlideover = (
    latestCompletedProgramAssessment: LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number],
  ) => {
    if (
      !latestCompletedProgramAssessment.previouslyCompletedProgramAssessmentOfSameType
    ) {
      setSelectedProgramAssessmentId(latestCompletedProgramAssessment.id);
      setIsAssessmentCompletedSlideoverOpen(true);
    } else {
      setSelectedAssessment(latestCompletedProgramAssessment.assessment);
      setIsAssessmentsCompletedSlideoverOpen(true);
    }
  };

  return (
    <div className="mt-6 flex flex-wrap gap-7">
      {latestCompletedProgramAssessments?.map(
        (latestCompletedProgramAssessment) => {
          const {
            id,
            completedAt,
            assessment: { type, shortName, name, increasingScoreTowardsHealth },
          } = latestCompletedProgramAssessment;

          if (
            latestCompletedProgramAssessment?.completionData?.__typename ===
            AssessmentCompletionDataTypename.MultipleChoiceSumScoredAssessmentCompletionData
          ) {
            const {
              completionData: { score, color, categorization },
            } = latestCompletedProgramAssessment;

            return (
              <LatestAssessmentCard
                key={id}
                shortName={shortName}
                color={color ?? 'neutral'}
                increasingScoreTowardsHealth={
                  increasingScoreTowardsHealth || false
                }
                completedDate={getTimeStamp(completedAt, false)}
                primaryScore={{
                  score,
                  categorization,
                }}
                onClick={() => {
                  setAssessmentForSlideover(latestCompletedProgramAssessment);
                }}
                hasPercentage={type === AssessmentType.Meq30}
              />
            );
          }

          if (
            latestCompletedProgramAssessment?.completionData?.__typename ===
            AssessmentCompletionDataTypename.HADSAssessmentCompletionData
          ) {
            const {
              completionData: { subscores },
            } = latestCompletedProgramAssessment;

            const anxietyCompletionData = subscores[0];
            const depressionCompletionData = subscores[1];

            return (
              <LatestAssessmentCard
                key={id}
                shortName={shortName}
                completedDate={getTimeStamp(completedAt, false)}
                color={'neutral'}
                increasingScoreTowardsHealth={
                  increasingScoreTowardsHealth || false
                }
                primaryScore={{
                  score: anxietyCompletionData.score,
                  categorization: anxietyCompletionData.categorization,
                }}
                secondaryScore={{
                  score: depressionCompletionData.score,
                  categorization: depressionCompletionData.categorization,
                }}
                onClick={() => {
                  setAssessmentForSlideover(latestCompletedProgramAssessment);
                }}
              />
            );
          }

          if (
            latestCompletedProgramAssessment?.completionData?.__typename ===
            AssessmentCompletionDataTypename.CustomAssessmentCompletionData
          ) {
            return (
              <ContentPreview
                key={`completedFormContentPreview_${id}`}
                contentType={ContentType.Form}
                className="max-w-[284px] !p-3"
                title={name}
                description={`Completed ${getTimeStamp(completedAt, false)}`}
                onClick={() => {
                  setAssessmentForSlideover(latestCompletedProgramAssessment);
                }}
              />
            );
          }

          return null;
        },
      )}
    </div>
  );
};

export default LatestAssessmentCards;

import {
  ActivityType,
  ProgramFollowUpQuery,
  AssessmentSchemaType,
  PatientDataFragment,
  PatientNameDataFragment,
  FollowUpDataFragment,
  SaveActivityTemplateInput,
} from '../../../generated/graphql';

import { getShortLink } from '../../lib/url';
import { getDurationMSS } from '../../lib/time';

import {
  ActivityOrResourceOptions,
  ContentType,
  FollowUpDecoratorKeys,
  SavedActivityInputPreview,
  activityTypeToContentType,
} from '../../lib/followUp';
import _ from 'lodash';

export const MAX_SUBJECT_LENGTH = 100;

export const getActivitiesList = (
  activitiesInputPreviewsHash: Record<string, SavedActivityInputPreview>,
) =>
  Object.values(activitiesInputPreviewsHash).sort(
    (
      activityA: SavedActivityInputPreview,
      activityB: SavedActivityInputPreview,
    ) =>
      activityA.sendFollowUpActivityInputRaw.dateCreated >
      activityB.sendFollowUpActivityInputRaw.dateCreated
        ? -1
        : 1,
  );

export const savedActivityInputPreviewToActivityTemplateInput = (
  savedActivityInputPreview: SavedActivityInputPreview,
): SaveActivityTemplateInput => {
  const omitted = _.omit(
    savedActivityInputPreview.sendFollowUpActivityInputRaw,
    FollowUpDecoratorKeys,
    'isCompletable',
    'programAssessmentSignatureUsers',
  );
  return {
    ...omitted,
    providerNote: omitted.providerNote ?? '',
  };
};

export type ProgramActivities =
  ProgramFollowUpQuery['programFollowUp']['programActivities'];
export type ProgramActivity = ProgramActivities[number];

export const activityTypeToContentDescription = (
  programActivity: ProgramActivity,
  activityType: ActivityType,
  assessmentSchemaType?: AssessmentSchemaType,
): string => {
  switch (activityType) {
    case ActivityType.Assessment:
      if (assessmentSchemaType === AssessmentSchemaType.Custom) {
        return programActivity.programAssessment?.assessment.description ?? '';
      }
      return programActivity.programAssessment?.assessment.name ?? '';
    case ActivityType.Link:
      return getShortLink(programActivity.activityLink?.url ?? '');
    case ActivityType.Audio:
      return getDurationMSS(programActivity.activityAudio?.duration);
    case ActivityType.Pdf:
    case ActivityType.PdfForm:
    case ActivityType.Esign:
      return programActivity.activityPDF?.media.isPdf ? 'PDF' : 'File';
    default:
      return '';
  }
};

export const getDraftedActivitiesHash = (
  programActivities: ProgramActivities,
) => {
  const draftedActivitiesHash = programActivities.reduce(
    (hash, programActivity, index) => {
      const activityType = programActivity.activityType;
      const assessmentSchemaType =
        programActivity.programAssessment?.assessment.schemaType;

      const contentType = activityTypeToContentType(
        activityType,
        assessmentSchemaType,
      );

      const description = activityTypeToContentDescription(
        programActivity,
        activityType,
        assessmentSchemaType,
      );

      const activityPreviewCardProps = {
        contentType,
        ...(activityType === ActivityType.Link && {
          activityUseType: programActivity.activityLink?.activityUseType,
        }),
        ...(activityType === ActivityType.Audio && {
          activityUseType: programActivity.activityAudio?.activityUseType,
        }),
        ...(activityType === ActivityType.Image && {
          activityUseType: programActivity.activityImage?.activityUseType,
        }),
        ...(Boolean(
          activityType === ActivityType.Pdf ||
            activityType === ActivityType.PdfForm ||
            activityType === ActivityType.Esign,
        ) && {
          activityUseType: programActivity.activityPDF?.activityUseType,
        }),
        ...(activityType === ActivityType.Text && {
          activityUseType: programActivity.activityText?.activityUseType,
        }),
        activityOrResourceOption: programActivity.isCompletable
          ? ActivityOrResourceOptions.Activity
          : ActivityOrResourceOptions.Resource,
        providerNote: programActivity.providerNote ?? undefined,
        title:
          activityType !== ActivityType.Action
            ? programActivity.name
            : 'Action',
        description,
        ...(contentType === ContentType.Image && {
          previewThumbnailImageUrl: programActivity.activityImage?.media.url,
        }),
        ...(contentType === ContentType.Link && {
          previewLinkUrl: programActivity.activityLink?.url,
          previewThumbnailImageUrl:
            programActivity.activityLink?.thumbnailMedia?.url,
        }),
        ...((activityType === ActivityType.Pdf ||
          activityType === ActivityType.PdfForm ||
          activityType === ActivityType.Esign) && {
          isActuallyPdfFile: programActivity.activityPDF?.media.isPdf,
        }),
      };

      // used to ensure order and key uniqueness if dates are the same
      const dateCreated = Date.now() + index;

      const sendFollowUpActivityInputRaw = {
        activityType,
        dateCreated,
        isSubmittable: true,
        isCompletable: programActivity.isCompletable,
        providerNote: programActivity.providerNote ?? undefined,
        ...(activityType === ActivityType.Assessment && {
          assessmentId: programActivity.programAssessment?.assessment.id,
        }),
        ...(activityType === ActivityType.Link && {
          activityLinkId: programActivity.activityLink?.id,
        }),
        ...(activityType === ActivityType.Audio && {
          activityAudioId: programActivity.activityAudio?.id,
        }),
        ...(activityType === ActivityType.Image && {
          activityImageId: programActivity.activityImage?.id,
        }),
        ...(Boolean(
          activityType === ActivityType.Pdf ||
            activityType === ActivityType.PdfForm ||
            activityType === ActivityType.Esign,
        ) && {
          activityPDFId: programActivity.activityPDF?.id,
        }),
        ...(activityType === ActivityType.Text && {
          activityTextId: programActivity.activityText?.id,
        }),
      };

      const savedActivityInputPreview = {
        sendFollowUpActivityInputRaw,
        activityPreviewCardProps,
      };

      return {
        ...hash,
        [dateCreated]: savedActivityInputPreview,
      };
    },
    {},
  );

  return draftedActivitiesHash;
};

export const convertPatientToPatientNameData = (
  patient: PatientDataFragment,
): PatientNameDataFragment => ({
  __typename: 'PatientName',
  id: patient.id,
  programInstanceId: patient.programInstanceId,
  email: patient.email,
  firstName: patient.firstName,
  lastName: patient.lastName,
  name: patient.name,
  isTestProgram: patient.isTestProgram,
  profileImageMedia: patient.profileImageMedia,
  createdAt: patient.createdAt,
});

export const getProgramAssessmentIdsNeedingSignature = (
  followUpData: FollowUpDataFragment,
  authedProviderUserId: string,
) => {
  return followUpData?.programActivities
    .filter(
      (activity) =>
        activity?.programAssessment?.currentSigner?.providerUser?.id ===
        authedProviderUserId,
    )
    .map((activity) => activity.programAssessment.id);
};

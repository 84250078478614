import { FC, useState } from 'react';
import classNames from 'classnames';

import {
  ProgramActivityDataFragment,
  UserType,
} from '../../../generated/graphql';
import { programActivityTitle } from '../../lib/programActivity';

import {
  getProgramActivityStatus,
  ProgramActivityStatus,
  ProgramActivityStatusOption,
} from '../ProgramActivityStatus';
import Linkifier from '../Linkifier';
import { ProgramActivityThumbnail } from '../ProgramActivityThumbnail';
import { ExclamationCircleIcon, SparklesIcon } from '@heroicons/react/outline';
import { getSignatureWarningMessage } from './helpers';

/*
    Component for an already-created activity/resource in the context of a program.
    Backed by the ProgramActivity back-end entity and ProgramActivityDataFragment GQL type.
    Differs from the existing ActivityPreviewCard in that it has been created and has
    a backing DB entity with state and is clickable to view the activity/resource details.
*/

const ProgramActivityCard: FC<{
  programActivity: ProgramActivityDataFragment;
  userType?: UserType;
  className?: string;
  isSmallStyle?: boolean;
  isFirstCard?: boolean;
  isLastCard?: boolean;
  onClickProgramActivity: (
    programActivity: ProgramActivityDataFragment,
  ) => void;
  authedProviderUserId?: string;
}> = ({
  programActivity,
  className,
  userType = UserType.ProviderUser,
  isFirstCard = false,
  isLastCard = false,
  isSmallStyle = false,
  onClickProgramActivity,
  authedProviderUserId,
}) => {
  const { providerNote } = programActivity;
  const title = programActivityTitle(programActivity);
  const programActivityStatus = getProgramActivityStatus(
    programActivity,
    userType,
  );

  const isPendingSignature =
    programActivityStatus === ProgramActivityStatusOption.PendingSignature;

  const isSmallAndPendingSignature = isSmallStyle && isPendingSignature;

  const [isHoverActive, setIsHoverActive] = useState(false);
  const showHoverWarning = isSmallAndPendingSignature && isHoverActive;

  const signatureWarningMessage = getSignatureWarningMessage(
    programActivity,
    userType,
    authedProviderUserId,
  );

  const handleMouseEnter = () => isSmallStyle && setIsHoverActive(true);
  const handleMouseLeave = () => isSmallStyle && setIsHoverActive(false);
  const handleClick = () =>
    !isSmallAndPendingSignature && onClickProgramActivity(programActivity);

  const cardClasses = classNames(
    'w-full border-neutral-75 p-4 focus:rounded-lg focus:outline-none focus:ring-2 focus:ring-green-50',
    {
      'cursor-pointer hover:bg-neutral-25': !isSmallAndPendingSignature,
      'cursor-default': isSmallAndPendingSignature,
      'border-b': isSmallStyle && !isLastCard,
      'group rounded-lg border bg-white': !isSmallStyle,
      'hover:bg-neutral-50': isPendingSignature,
      'rounded-t-xl': isSmallStyle && isFirstCard,
      'rounded-b-xl': isSmallStyle && isLastCard,
    },
    className,
  );

  return (
    <div
      className={cardClasses}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!isSmallStyle && isPendingSignature && (
        <div className="mb-5 flex w-full flex-row items-start justify-start rounded-md bg-neutral-25 p-4 group-hover:bg-neutral-50">
          <SparklesIcon className="mr-2 h-5 w-5 text-neutral-125" />
          <div className="w-full text-caption text-neutral-125">
            {signatureWarningMessage}
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex items-center',
          !isSmallStyle && providerNote && 'border-b border-neutral-50 pb-4',
        )}
      >
        <ProgramActivityThumbnail
          programActivity={programActivity}
          className="mr-4 h-12 w-12"
        />
        {isSmallStyle ? (
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-col items-start justify-center">
              {showHoverWarning ? (
                <div className="grow break-words pr-5 text-small-caption text-neutral-125">
                  {signatureWarningMessage}
                </div>
              ) : (
                <>
                  <div
                    className={classNames(
                      'grow break-words text-caption font-medium text-neutral-150',
                      isSmallStyle && 'line-clamp-2',
                    )}
                  >
                    {title}
                  </div>
                  <div className="whitespace-pre-wrap break-words text-caption text-neutral-125 line-clamp-1">
                    {providerNote}
                  </div>
                </>
              )}
            </div>
            {showHoverWarning && (
              <div className="relative">
                <ExclamationCircleIcon className="h-5 w-5 text-orange-100" />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="grow text-caption font-medium text-neutral-150 line-clamp-2">
              {title}
            </div>
            <ProgramActivityStatus
              programActivity={programActivity}
              className="ml-4 flex-none"
              userType={userType}
            />
          </>
        )}
      </div>
      {!isSmallStyle && providerNote && (
        <div className="mt-4 whitespace-pre-wrap break-words text-caption text-neutral-125">
          <Linkifier text={providerNote} />
        </div>
      )}
    </div>
  );
};

export default ProgramActivityCard;

import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Transition as HistoryTransition } from 'history';

import { Transition } from '@headlessui/react';

import {
  useForm,
  useFieldArray,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';

import {
  useCreateCustomAssessmentMutation,
  useUpdateCustomAssessmentMutation,
  useCustomAssessmentQuery,
  ActivityType,
  useAddActivityToProviderCollectionMutation,
  SubscriptionTierV2,
  AssessmentQuestionType,
} from '../../../generated/graphql';

import { resizeTextArea } from '../../lib/form';
import { defaultTransitionProps } from '../../lib/animation';
import { maxWordLength } from '../../lib/form-validators';

import useBlocker from '../../hooks/useBlocker';
import usePaintScreen from '../../hooks/usePaintScreen';

import Spinner from '../../svgs/Spinner';
import Button from '../../components/Button';
import InputGroup from '../../components/InputGroup';
import TextAreaGroup from '../../components/TextAreaGroup';
import PageContainer from '../../components/Containers/PageContainer';
import UnsavedChangesModal from '../../components/Modals/UnsavedChangesModal';

import FormBlock from './FormBlock';
import QuestionContainer from './QuestionContainer';
import { QuestionForm } from './QuestionContainer/helpers';
import {
  defaultQuestionSchema,
  formatQuestionDataForForm,
  formatQuestionFormData,
  isMultiSignatureValid,
  defaultSignatureUserSchemaIndividual,
  defaultSignatureUserSchemaTeam,
} from './helpers';
import SignatureSettingsModal from '../../components/Modals/MultiSignatureModals/SignatureSettingsModal';
import { useAuth } from '../../../contexts/AuthContext';
import SignatureFlag from '../../svgs/SignatureFlag';
import { customToast } from '../../components/ToastAlert/customToast';
import IconButton from '../../components/IconButton';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';

const MAX_NAME_OPTION_LENGTH = 132;
const MAX_NAME_OPTION_WARNING_LENGTH = 100;

const FormBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const assessmentId = params.assessmentId ?? '';
  const { authedProviderUser } = useAuth();

  const subscriptionTier = authedProviderUser?.provider.subscriptionTierV2;

  const defaultSignatureUserSchema =
    subscriptionTier === SubscriptionTierV2.Team
      ? defaultSignatureUserSchemaTeam
      : defaultSignatureUserSchemaIndividual;

  const location = useLocation();
  // State to communicate that the form being built is within the context of a provider collection
  // and should be added to the collection, and redirected to it within the Library after saving
  const fromProviderCollectionId = (
    location.state as { fromProviderCollectionId?: string }
  )?.fromProviderCollectionId;
  // State to communicate that the form being built is within the context of the webpage builder
  // and should be redirected back there to attach to the Provider's webpage after saving
  const fromPage = (location.state as { fromPage?: string })?.fromPage;
  const fromMyWebpage = fromPage === 'my-webpage';

  const paintScreen = usePaintScreen();

  const { data: customAssessmentData, loading: customAssessmentDataLoading } =
    useCustomAssessmentQuery({
      variables: {
        assessmentId,
      },
      skip: !assessmentId,
    });

  const showPage = paintScreen && !customAssessmentDataLoading;
  const existingAssessment = customAssessmentData?.assessment;

  useEffect(() => {
    if (existingAssessment) {
      const { name, description, questions, assessmentSignatureUsers } =
        existingAssessment;
      if (!watchName) {
        setValue('name', name);
        setValue('description', description);
        setValue('questions', formatQuestionDataForForm(questions));
        setValue(
          'assessmentSignatureUsers',
          assessmentSignatureUsers.length > 0
            ? assessmentSignatureUsers.map(({ __typename, ...rest }) => rest)
            : defaultSignatureUserSchema,
        );
      }
    }
  }, [existingAssessment]);

  const [
    createCustomAssessmentMutation,
    { loading: createCustomAssessmentMutationLoading },
  ] = useCreateCustomAssessmentMutation();

  const [
    updateCustomAssessmentMutation,
    { loading: updateCustomAssessmentMutationLoading },
  ] = useUpdateCustomAssessmentMutation();

  const [addActivityToProviderCollection] =
    useAddActivityToProviderCollectionMutation();

  const {
    control,
    watch,
    register,
    setValue,
    setFocus,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<QuestionForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      description: '',
      questions: [defaultQuestionSchema],
      assessmentSignatureUsers: defaultSignatureUserSchema,
    },
  });

  const hasUnsavedChanges = Boolean(isDirty);
  const [submitting, setSubmitting] = useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);
  const [isSignatureSettingsModalOpen, setIsSignatureSettingsModalOpen] =
    useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<
    number | null
  >(null);

  const openSignatureSettingsModal = () => {
    setIsSignatureSettingsModalOpen(true);
  };

  const triggerUnsavedChangesModal = Boolean(hasUnsavedChanges && !submitting);

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [triggerUnsavedChangesModal],
  );

  useBlocker(blocker, triggerUnsavedChangesModal);

  const watchName = watch('name');
  const nameRegister = register('name', {
    required: { value: true, message: 'Please give your assessment a title' },
    maxLength: {
      value: MAX_NAME_OPTION_LENGTH,
      message: `Please ensure the title is less than ${MAX_NAME_OPTION_LENGTH} characters`,
    },
    onBlur: () => setValue('name', watchName.trim()),
  });

  const watchDescription = watch('description');

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const { ref: descriptionRegisterRef, ...descriptionRegister } = register(
    'description',
    {
      validate: maxWordLength,
      onChange: () => resizeTextArea(descriptionRef),
      onBlur: () => setValue('description', watchDescription.trim()),
    },
  );

  // resizes description on initial load
  useEffect(() => {
    resizeTextArea(descriptionRef);
  }, [descriptionRegisterRef]);

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const watchQuestions = watch('questions');
  // https://react-hook-form.com/docs/usefieldarray - "Controlled Field Array"
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchQuestions[index],
    };
  });

  const watchAssessmentSignatureUsers = watch('assessmentSignatureUsers');

  const hasMultiSignature = watchQuestions.some(
    (question) =>
      question.questionType === AssessmentQuestionType.MultiSignature,
  );

  // Reset default signature users when no multi-signature questions are present
  useEffect(() => {
    if (!hasMultiSignature) {
      setValue('assessmentSignatureUsers', defaultSignatureUserSchema);
    }
  }, [hasMultiSignature, setValue]);

  const onSubmit: SubmitHandler<QuestionForm> = async ({
    name,
    description,
    questions,
    assessmentSignatureUsers,
  }) => {
    setSubmitting(true);

    const descriptionWithDefault = Boolean(description)
      ? description
      : 'None provided';

    const formattedQuestions = formatQuestionFormData(questions);

    const createCustomAssessmentInput = {
      name,
      description: descriptionWithDefault,
      questions: formattedQuestions,
      ...(assessmentSignatureUsers.length > 1 && {
        assessmentSignatureUsers,
      }),
    };

    const updateCustomAssessmentInput = {
      assessmentId,
      ...createCustomAssessmentInput,
    };

    // Validation for multisignature
    if (
      hasMultiSignature &&
      !isMultiSignatureValid(
        formattedQuestions,
        assessmentSignatureUsers,
        setError,
      )
    ) {
      setSubmitting(false);
      return;
    }

    try {
      if (assessmentId) {
        await updateCustomAssessmentMutation({
          variables: {
            input: updateCustomAssessmentInput,
          },
          onCompleted: (data) => {
            if (data?.updateCustomAssessment) {
              customToast.success(
                `Successfully saved ${data.updateCustomAssessment.name}!`,
              );
            }
          },
        });
      } else {
        const { data } = await createCustomAssessmentMutation({
          variables: {
            input: createCustomAssessmentInput,
          },
        });

        const assessmentId = data?.createCustomAssessment?.id;

        if (fromMyWebpage) {
          // Redirect to the webpage builder to attach the assessment to the provider's webpage
          navigate('/my-webpage', {
            state: {
              intakeFormAssessmentIdToAttach: assessmentId,
            },
          });
          return;
        }

        if (fromProviderCollectionId) {
          try {
            await addActivityToProviderCollection({
              variables: {
                input: {
                  activityId: assessmentId,
                  activityType: ActivityType.Assessment,
                  providerCollectionId: fromProviderCollectionId,
                },
              },
            });
          } catch (err) {
            // Don't block the user from saving the assessment if it fails to add to the provider collection
            // Since the ID is not guaranteed to be valid.
            console.error(
              'Error adding assessment to provider collection:',
              err,
            );
          }
        }

        if (data?.createCustomAssessment) {
          customToast.success(
            `Successfully saved ${data.createCustomAssessment.name}!`,
          );
        }
      }

      navigate(
        '/library',
        fromProviderCollectionId
          ? {
              state: { providerCollectionId: fromProviderCollectionId },
            }
          : undefined,
      );
    } catch (err) {
      console.error('Errors submitting:', err);
      customToast.error('Something went wrong - please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<QuestionForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  return (
    <>
      <div className="relative scroll-smooth">
        <Transition show={showPage} {...defaultTransitionProps}>
          <div className="t-16 fixed z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-3 px-20">
            <div className="w-[289px]">
              <IconButton
                IconComponent={ArrowNarrowLeftIcon}
                iconClassName="text-green-100 w-5"
                aria-label="Back"
                className="rounded-xl px-3 py-2"
                onClick={() =>
                  fromPage ? navigate(`/${fromPage}`) : navigate(-1)
                }
              >
                <div className="small-caption ml-2 font-bold text-green-100">
                  Back
                </div>
              </IconButton>
            </div>
            <span className="text-category font-medium text-green-150 line-clamp-1">
              {Boolean(watchName) ? watchName : 'Untitled form'}
            </span>
            <div className="flex w-[289px] flex-row items-center justify-end">
              {hasMultiSignature && (
                <Button
                  title="Form signatures"
                  iconPosition="left"
                  className="mr-3 px-5 py-1.5"
                  onClick={openSignatureSettingsModal}
                  size="small"
                  theme="secondary"
                  IconComponent={SignatureFlag}
                />
              )}
              <Button
                title="Save"
                size="small"
                className="px-6 py-1.5"
                onClick={handleSubmit(onSubmit, handleErrors)}
                disabled={
                  createCustomAssessmentMutationLoading ||
                  updateCustomAssessmentMutationLoading
                }
                {...(submitting && {
                  IconComponent: Spinner,
                })}
              />
            </div>
          </div>
        </Transition>
        <PageContainer
          noPadding
          containerClassName="bg-white min-h-[calc(100vh-48px)] h-auto items-center pt-24 pb-16"
        >
          <Transition
            show={showPage}
            {...defaultTransitionProps}
            beforeEnter={() => {
              window.scrollTo(0, 0);
            }}
          >
            <form>
              {/* Add assessment title and description */}
              <FormBlock>
                <InputGroup
                  autoFocus
                  required
                  labelHidden
                  backgroundHidden
                  type="text"
                  label="Form title"
                  inputSize="extra-small"
                  errorMessage={errors.name?.message}
                  placeholder={'Untitled form'}
                  containerClassName="mb-3"
                  className="rounded-none border-0 border-b-2 px-0 pb-1 text-big-label"
                  currentLengthValue={watchName?.length ?? 0}
                  maxLengthValue={MAX_NAME_OPTION_LENGTH}
                  maxLength={MAX_NAME_OPTION_LENGTH}
                  characterCounter={
                    watchName?.length > MAX_NAME_OPTION_WARNING_LENGTH
                  }
                  {...nameRegister}
                />
                <TextAreaGroup
                  value={watchDescription}
                  label="Form description"
                  placeholder="Add a description visible only to you and your team. Clients won't see this."
                  rows={1}
                  inputSize="small"
                  labelHidden
                  className="focused:border-green-50 h-auto w-full"
                  errorMessage={errors.description?.message}
                  {...descriptionRegister}
                  ref={(event) => {
                    descriptionRegisterRef(event);
                    descriptionRef.current = event;
                  }}
                />
              </FormBlock>
              {/* Question list */}
              {controlledFields.map((question, questionIndex) => (
                <QuestionContainer
                  key={question.id}
                  question={question}
                  questionIndex={questionIndex}
                  isLastQuestion={questionIndex === fields.length - 1}
                  control={control}
                  errors={errors}
                  insert={insert}
                  remove={remove}
                  update={update}
                  register={register}
                  setValue={setValue}
                  setFocus={setFocus}
                  openSignatureSettingsModal={openSignatureSettingsModal}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                  watchAssessmentSignatureUsers={watchAssessmentSignatureUsers}
                  watch={watch}
                  currentQuestionIndex={currentQuestionIndex}
                  fromMyWebpage={fromMyWebpage}
                />
              ))}
            </form>
          </Transition>
        </PageContainer>
      </div>
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => navTransition?.retry()}
      />
      <SignatureSettingsModal
        setClosed={() => setIsSignatureSettingsModalOpen(false)}
        isOpen={isSignatureSettingsModalOpen}
        watchAssessmentSignatureUsers={watchAssessmentSignatureUsers}
        watchQuestions={watchQuestions}
        setValue={setValue}
        isTeamAccount={subscriptionTier === SubscriptionTierV2.Team}
      />
      {!showPage && <Spinner className="mx-auto mt-24" />}
    </>
  );
};

export default FormBuilder;

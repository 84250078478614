import { Transition } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  Navigate,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import {
  BasicProviderNoteTemplateDataFragment,
  ProviderNoteTemplateDataFragment,
  useMarkProgramSeenMutation,
  useMeProviderProgramTagsQuery,
  usePatientQuery,
  useProgramActivitiesLazyQuery,
  useProgramNotesQuery,
} from '../../../generated/graphql';

import { defaultTransitionProps } from '../../lib/animation';

import PageContainer from '../../components/Containers/PageContainer';
import Tabs from '../../components/Tabs';
import ToastAlert from '../../components/ToastAlert';

import Avatar from '../../components/Avatar';
import TestClientPill from '../../components/TestClientPill';

import useFixPage from '../../hooks/useFixPage';

import DemoClientInfoButton from '../../components/DemoClientInfoButton';
import ProgramTagManager from '../../components/ProgramTag/ProgramTagManager';
import { TabProps } from '../../components/Tabs/Tab';
import { PatientProfileContext } from './helpers';
import { AnalyticsPage } from '../../../lib/analytics';
import { useAuth } from '../../../contexts/AuthContext';
import Loading from '../Loading';
import { isPatientNewFromStorefront } from '../../lib/patient';
import IconButton from '../../components/IconButton';

import { PlusSmIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Button from '../../components/Button';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import { marked } from 'marked';
import NoteCard from '../../components/NoteCard';
import Pin from '../../svgs/Pin';
import AssignProviderUsersModal from '../../components/Modals/ClientAssignmentModals/AssignProviderUsersModal';

export function usePatientProfileContext() {
  return useOutletContext<PatientProfileContext>();
}

const PatientProfile = () => {
  const params = useParams();
  const programId = params.programId || '';

  const navigate = useNavigate();

  useFixPage();

  const { authedProviderUser, showUpgradeBanner, willHitClientLimit } =
    useAuth();
  const hasStripeIntegrationSetup =
    authedProviderUser?.provider.stripeAccountId;

  const [isAssignProviderUsersModalOpen, setIsAssignProviderUsersModalOpen] =
    useState(false);

  const [isNoteActionsMenuOpen, setIsNoteActionsMenuOpen] = useState(false);
  const [selectedNoteTemplate, setSelectedNoteTemplate] = useState<
    ProviderNoteTemplateDataFragment | BasicProviderNoteTemplateDataFragment
  >();

  // Enforces client downgrade flow
  useEffect(() => {
    if (willHitClientLimit(0)) {
      // Client limit is surpassed
      navigate('/clients');
    }
  }, [willHitClientLimit]);

  const {
    data: patientData,
    loading: patientLoading,
    refetch: refetchPatient,
  } = usePatientQuery({
    variables: {
      programId,
    },
    onError: (error) => {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch client."
          level="error"
        />
      ));
      navigate('/clients', { replace: true });
    },
  });

  const { data: programNotesData, refetch: refetchPinnedNotes } =
    useProgramNotesQuery({
      variables: {
        programId,
      },
      skip: !programId,
    });

  // Convert markdown notes to HTML
  const pinnedNotes = useMemo(() => {
    return (
      programNotesData?.programNotes
        ?.filter((programNote) => programNote.isPinnedNote)
        .map((programNote) => ({
          ...programNote,
          note: programNote.note
            ? marked.parse(programNote.note)
            : programNote.note,
        })) || []
    );
  }, [programNotesData?.programNotes]);

  const [isPinnedNotesPanelOpen, setIsPinnedNotesPanelOpen] = useState(false);

  const hasPinnedNotes = Boolean(pinnedNotes?.length > 0);
  const showPinnedNotesPanel = isPinnedNotesPanelOpen && hasPinnedNotes;

  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const [markProgramSeenMutation] = useMarkProgramSeenMutation();

  const [
    getProgramActivities,
    {
      data: programActivitiesData,
      error: programActivitiesError,
      loading: programActivitiesLoading,
    },
  ] = useProgramActivitiesLazyQuery({
    variables: {
      programId,
    },
  });

  const patient = patientData?.patient ?? null;

  useEffect(() => {
    const markProgramSeen = async () => {
      // Currently this is set to only mark an unseen storefront patient as seen,
      // because that's all we're leveraging it for and to save unnecessary calls,
      // but this could be refactored to run every time the patient is loaded, which
      // would properly indicate "last seen" state.
      if (patient && isPatientNewFromStorefront(patient)) {
        await markProgramSeenMutation({
          variables: {
            programId: patient.programInstanceId,
          },
        });
        await refetchPatient();
      }
    };

    markProgramSeen();
  }, [patient]);

  // Existence of startTime indicates that the patient has accepted a mobile invite / registered on the app
  const isMobileActiveProfile =
    patient?.startTime && patient?.deactivatedAt === null;

  // Disable profile for archived clients
  if (patient?.deactivatedAt) {
    return <Navigate to="/clients" replace />;
  }

  const tabList: TabProps[] = [
    {
      route: 'follow-ups',
      name: 'Mail',
    },
    {
      route: 'messages',
      name: 'Chat',
      count: Boolean(patient?.unreadCommentCount)
        ? patient?.unreadCommentCount
        : undefined,
    },
    {
      route: 'notes',
      name: 'Private Notes',
    },
    {
      route: 'activities',
      name: 'Activity Status',
    },
    {
      route: 'documents',
      name: 'Documents',
    },
    ...(hasStripeIntegrationSetup
      ? [
          {
            route: 'invoices',
            name: 'Invoices',
          },
        ]
      : []),
    {
      route: 'account',
      name: 'Account',
    },
  ];

  const assignedProviderUserCount = patient?.assignedProviderUsers?.length;
  const hasAssignedPractitioners = Boolean(assignedProviderUserCount);
  const canAssignPractitioners =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  return (
    <PageContainer
      noPadding
      containerClassName="pt-6"
      onClick={() => {
        if (isNoteActionsMenuOpen) {
          setIsNoteActionsMenuOpen(false);
          setSelectedNoteTemplate(undefined);
        }
      }}
    >
      <Transition
        className="flex h-full flex-grow flex-col"
        show={Boolean(patient)}
        {...defaultTransitionProps}
      >
        <div className="flex w-full flex-row items-center justify-between px-20">
          <div className="flex flex-row items-center justify-start gap-x-4">
            <Avatar
              size="medium-large"
              name={patient?.firstName ?? undefined}
              imageUrl={patient?.profileImageMedia?.url}
            />
            <div className="flex flex-col items-start justify-start">
              <div className="font-serif text-subtitle-small text-green-150">
                {`${patient?.firstName} ${patient?.lastName}`}
              </div>
              <div className="font-sans text-small-caption text-neutral-125">
                {`${patient?.email}`}
              </div>
            </div>
            {patient?.isTestProgram && <TestClientPill />}
            {patient?.isTestProgram && <DemoClientInfoButton />}
            {patient && (
              <ProgramTagManager
                programId={patient.programInstanceId}
                availableProgramTags={providerProgramTags}
                refreshAvailableProgramTags={refetchMeProviderProgramTags}
                selectedProgramTags={patient.tags}
                analyticsPage={AnalyticsPage.ClientProfile}
                className="max-w-lg pr-4"
              />
            )}
          </div>
          <div
            className={classNames(
              'flex flex-row items-center justify-between',
              canAssignPractitioners && 'cursor-pointer',
            )}
            onClick={() => {
              if (canAssignPractitioners) {
                setIsAssignProviderUsersModalOpen(true);
              }
            }}
          >
            {hasAssignedPractitioners && (
              <div className="flex flex-row items-center justify-start gap-x-2">
                <div className="relative">
                  <div
                    className={classNames(
                      'relative',
                      patient?.assignedProviderUsers.length === 1 && 'left-16',
                      patient?.assignedProviderUsers.length === 2 && 'left-8',
                    )}
                  >
                    {patient?.assignedProviderUsers?.map(
                      (assignedProviderUser, index) => {
                        const countShown =
                          assignedProviderUserCount > 3 && index === 2;

                        return (
                          <Avatar
                            key={`assignedProviderUser_${assignedProviderUser.id}`}
                            size="medium-plus"
                            name={
                              index < 2 || !countShown
                                ? assignedProviderUser?.name
                                : `+${assignedProviderUserCount - 2}`
                            }
                            imageUrl={
                              assignedProviderUser?.profileImageMedia?.url
                            }
                            className={classNames(
                              'absolute -top-5 border-[2.5px] border-white',
                              index === 0 && '-left-28 z-10',
                              index === 1 && '-left-20 z-20',
                              index === 2 && '-left-12 z-30',
                              assignedProviderUserCount > 3 &&
                                index > 2 &&
                                'hidden',
                            )}
                            textClassName={classNames(
                              'bg-neutral-50 text-neutral-125',
                              countShown && 'text-caption',
                            )}
                            useFullName={countShown}
                          />
                        );
                      },
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start">
                  <div className="text-small-action font-semibold text-green-150">
                    {assignedProviderUserCount === 1 && (
                      <span>{'Practitioner'.toUpperCase()}</span>
                    )}
                    {assignedProviderUserCount > 1 && (
                      <span>{'Care Team'.toUpperCase()}</span>
                    )}
                  </div>
                  <div className="text-caption text-green-150">
                    {patient?.assignedProviderUsers
                      ?.slice(0, 4)
                      ?.map((assignedProviderUser, index, array) => {
                        const isAbbreviated =
                          patient.assignedProviderUsers.length > 3;
                        const displayName = isAbbreviated
                          ? assignedProviderUser.name
                              .split(' ')
                              .map((name) => name[0])
                              .join('')
                              .toUpperCase()
                          : assignedProviderUser.name;
                        return (
                          <span key={`displayName_${assignedProviderUser.id}`}>
                            {displayName}
                            {index < array.length - 1 ? ', ' : ''}
                            {isAbbreviated && index === array.length - 1
                              ? '...'
                              : ''}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {canAssignPractitioners && (
              <>
                {hasAssignedPractitioners ? (
                  <IconButton
                    IconComponent={PlusSmIcon}
                    aria-label="Add to care team"
                    className="ml-8 !rounded-full bg-neutral-50 p-2 hover:bg-neutral-100/75"
                    iconClassName="h-5 w-5 text-neutral-125"
                    onClick={() => setIsAssignProviderUsersModalOpen(true)}
                  />
                ) : (
                  <Button
                    IconComponent={PlusSmIcon}
                    title="Assign practitioners"
                    aria-label="Add to care team"
                    iconPosition="left"
                    size="small"
                    theme="secondary"
                    onClick={() => setIsAssignProviderUsersModalOpen(true)}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="mt-3 flex h-[48px] w-full items-center justify-between px-20">
          <Tabs tabList={tabList} fullWidth />
        </div>

        <div
          className={classNames(
            'flex w-full',
            !showUpgradeBanner
              ? 'max-h-[calc(100vh-208px)] min-h-[calc(100vh-208px)]'
              : 'max-h-[calc(100vh-264px)] min-h-[calc(100vh-264px)]',
          )}
        >
          <div
            className={classNames(
              'relative mt-[0.5px] flex min-h-full flex-col bg-neutral-50 transition-all duration-500 ease-in-out',
              showPinnedNotesPanel ? 'w-[35%]' : 'w-0',
            )}
          >
            <div
              className={classNames(
                'absolute top-6 z-50 transition-all duration-500 ease-in-out',
                showPinnedNotesPanel ? '-right-8 lg:-right-5' : '-right-10',
              )}
            >
              <IconButton
                IconComponent={Pin}
                iconClassName="text-neutral-125 h-[14px] w-[14px]"
                className="rounded-2xl border-[0.5px] border-neutral-75 bg-white p-2 shadow-100"
                onClick={() =>
                  setIsPinnedNotesPanelOpen(!isPinnedNotesPanelOpen)
                }
                aria-label={
                  !hasPinnedNotes
                    ? 'Pin private note to expand menu'
                    : !isPinnedNotesPanelOpen
                    ? 'See pinned note'
                    : 'Hide pinned note'
                }
                disabled={!hasPinnedNotes}
              />
            </div>

            <div
              className={classNames(
                'h-full w-full flex-col items-center justify-between overflow-y-scroll transition-all duration-1000 ease-in-out',
                showPinnedNotesPanel ? 'opacity-100' : 'opacity-0',
              )}
            >
              {showPinnedNotesPanel &&
                pinnedNotes?.map((note) => (
                  <NoteCard
                    key={note.id}
                    programNote={note}
                    className="m-5 rounded-lg border border-green-100"
                  />
                ))}
            </div>
          </div>
          <div
            className={classNames(
              'flex w-full flex-grow transition-all duration-150 ease-in-out',
              showPinnedNotesPanel ? 'pl-10 pr-20' : 'px-20',
            )}
          >
            <Outlet
              context={{
                patient,
                refetchPatient,
                getProgramActivities,
                programActivitiesData,
                programActivitiesError,
                programActivitiesLoading,
                isMobileActiveProfile,
                canAssignPractitioners,
                refetchPinnedNotes,
                isNoteActionsMenuOpen,
                setIsNoteActionsMenuOpen,
                hasPinnedNotes,
                setIsPinnedNotesPanelOpen,
                selectedNoteTemplate,
                setSelectedNoteTemplate,
              }}
            />
          </div>
        </div>
      </Transition>
      {patientLoading && <Loading />}

      {canAssignPractitioners && (
        <AssignProviderUsersModal
          isOpen={isAssignProviderUsersModalOpen}
          setClosed={() => setIsAssignProviderUsersModalOpen(false)}
          selectedPatient={patient}
          refetchPatient={refetchPatient}
        />
      )}
    </PageContainer>
  );
};

export default PatientProfile;

import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ActivityType,
  LocationType,
  NotificationDataFragment,
  PatientDataPermission,
  UpcomingScheduledEventFragment,
  useMeProviderProgramTagsWithCountQuery,
  useUninvitedEventInviteesQuery,
  useUpcomingScheduledEventsQuery,
} from '../../../generated/graphql';

import { useAuth } from '../../../contexts/AuthContext';
import { useGlobalModalsContext } from '../../../contexts/GlobalModalsContext';

import { AnalyticsPage } from '../../../lib/analytics';

import IllustrationCalendar from '../../svgs/IllustrationCalendar';

import PageContainer from '../../components/Containers/PageContainer';

import IllustrationFaces from '../../svgs/IllustrationFaces';
import { PatientRow } from '../../types/PatientManagement';
import { QuickActionProps } from './QuickAction';

import {
  ChatIcon,
  MailIcon,
  SparklesIcon,
  UserIcon,
} from '@heroicons/react/outline';
import _ from 'lodash';
import AddToLibraryMenu from '../../components/AddToLibraryMenu';
import Button from '../../components/Button';
import AddAudioModal from '../../components/Modals/AddAudioModal';
import AddImageModal from '../../components/Modals/AddImageModal';
import AddLinkModal from '../../components/Modals/AddLinkModal';
import AddOrEditTextModal from '../../components/Modals/AddOrEditTextModal';
import AddPDFModal from '../../components/Modals/AddPDFModal';
import AddPatientsModal from '../../components/Modals/AddPatientsModal';
import useIncrementNowDate from '../../hooks/useIncrementNowDate';
import {
  HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
  HAS_DISMISSED_BOOK_A_CALL,
  HAS_DISMISSED_PAYMENTS_SETUP,
  HAS_DISMISSED_SCHEDULE_SETUP,
  HAS_DISMISSED_STOREFRONT_SETUP,
} from '../../lib/storage';
import { openBookACallLink } from '../../lib/url';
import NotificationItem from './NotificationItem';
import OnboardingNotification from './OnboardingNotification';
import SelectPatientModal from './SelectPatientModal';
import UpcomingEvent from '../../components/UpcomingEvent';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';
import useLocalStorageValue from '../../hooks/useLocalStorageValue';
import { Transition } from '@headlessui/react';
import { defaultTransitionProps } from '../../lib/animation';

const BanknotesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="mr-2 h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
    />
  </svg>
);

const DocumentPlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="mr-2 h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
    />
  </svg>
);

type NotificationElem = QuickActionProps | NotificationDataFragment;
const isNotification = (
  elem: NotificationElem,
): elem is NotificationDataFragment => {
  return _.has(elem, '__typename');
};

const Home = () => {
  const navigate = useNavigate();

  const {
    authedProviderUser,
    refreshAuthedProviderUserNotifications,
    willHitClientLimit,
  } = useAuth();
  const { showUpgradeModal } = useGlobalModalsContext();

  const isOwnerOrAdmin =
    isProviderUserOwnerOrAdminAuthorized(authedProviderUser);

  const hasCalendlyIntegration = Boolean(
    authedProviderUser?.provider.calendlyIntegration,
  );

  const hasStripeIntegration = Boolean(
    authedProviderUser?.provider.stripeAccountId,
  );

  const now = useIncrementNowDate();

  const { data: upcomingEventsData } = useUpcomingScheduledEventsQuery({
    variables: {
      input: {
        minStartTime: now,
        count: 3,
        isCanceled: false,
      },
    },
    skip: !hasCalendlyIntegration,
  });

  const demoEvents: UpcomingScheduledEventFragment[] = [
    {
      name: '45 Minute Session',
      calendlyEventUri: '',
      startTime: now.toISO(),
      endTime: now.toISO(),
      hostName: '',
      isCanceled: false,
      location: {
        type: LocationType.Custom,
      },
      invitees: [
        {
          firstName: 'Demo',
          lastName: 'Client',
          email: 'a@b.com',
        },
      ],
    },
  ];

  const { data: uninvitedEventInviteesData } = useUninvitedEventInviteesQuery();

  const upcomingEvents = hasCalendlyIntegration
    ? upcomingEventsData?.scheduledEvents
    : demoEvents;

  const hasUpcomingEvents = Boolean(upcomingEvents?.length);

  const uniqueInvitees: PatientRow[] | undefined =
    uninvitedEventInviteesData?.uninvitedEventInvitees.map((invitee) => ({
      name: `${invitee.firstName} ${invitee.lastName}`,
      email: invitee.email,
    }));

  const [showPrefilledPatientsList, setShowPrefilledPatientsList] =
    useState(false);

  const [hasDismissedBookACallStep, setHasDismissedBookACallStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_BOOK_A_CALL,
      authedProviderUser.email,
    );

  const [hasDismissedScheduleStep, setHasDismissedScheduleStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_SCHEDULE_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedPaymentsStep, setHasDismissedPaymentsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_PAYMENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedCalendlyClientsStep, setHasDismissedCalendlyClientsStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_ADD_CALENDLY_CLIENTS_SETUP,
      authedProviderUser.email,
    );

  const [hasDismissedStorefrontStep, setHasDismissedStorefrontStep] =
    useLocalStorageValue<boolean>(
      HAS_DISMISSED_STOREFRONT_SETUP,
      authedProviderUser.email,
    );

  const showBookACall = Boolean(
    authedProviderUser && !hasDismissedBookACallStep,
  );

  const showScheduleSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasCalendlyIntegration &&
    !hasDismissedScheduleStep;

  const showPaymentsSetup =
    authedProviderUser &&
    isOwnerOrAdmin &&
    !hasStripeIntegration &&
    !hasDismissedPaymentsStep;

  const showCalendlyClientsSetup = Boolean(
    authedProviderUser &&
      hasCalendlyIntegration &&
      !hasDismissedCalendlyClientsStep,
  );

  const showSetupStorefront = Boolean(
    authedProviderUser &&
      isOwnerOrAdmin &&
      !authedProviderUser.storefrontSlug &&
      !hasDismissedStorefrontStep,
  );

  const onboardingSteps: QuickActionProps[] = useMemo(
    () => [
      ...(showBookACall
        ? [
            {
              title: 'Book a call with us',
              description: 'Get personal guidance from the Homecoming team',
              IconComponent: IllustrationFaces,
              iconClassName: 'text-purple-125',
              buttonTitle: 'Book a Call',
              onClick: openBookACallLink,
              onDismiss: () => setHasDismissedBookACallStep(true),
            },
          ]
        : []),
      ...(showScheduleSetup
        ? [
            {
              title: 'Set up your schedule',
              description: 'Connect your Calendly account with Homecoming',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-green-125',
              buttonTitle: 'Connect Calendly',
              onClick: () => {
                navigate('/integrations/calendly');
              },
              onDismiss: () => setHasDismissedScheduleStep(true),
            },
          ]
        : []),
      ...(showPaymentsSetup
        ? [
            {
              title: 'Set up payments',
              description: 'Connect your Stripe account with Homecoming',
              IconComponent: IllustrationCalendar,
              iconClassName: 'text-green-125',
              buttonTitle: 'Connect Stripe',
              onClick: () => {
                navigate('/integrations/stripe');
              },
              onDismiss: () => setHasDismissedPaymentsStep(true),
            },
          ]
        : []),
      ...(showCalendlyClientsSetup && uniqueInvitees?.length
        ? [
            {
              title: 'Import clients',
              description:
                'Some clients from Calendly are not on Homecoming, would you like to add them?',
              IconComponent: IllustrationFaces,
              iconClassName: 'text-green-125 h-32 w-32',
              onClick: () => {
                setShowPrefilledPatientsList(true);
                addPatients();
              },
              onDismiss: () => setHasDismissedCalendlyClientsStep(true),
            },
          ]
        : []),
      ...(showSetupStorefront
        ? [
            {
              title: 'Set up your webpage',
              description:
                'Customize your webpage and start accepting new clients',
              buttonTitle: 'Set up webpage',
              onClick: () => {
                navigate('/my-webpage');
              },
              onDismiss: () => setHasDismissedStorefrontStep(true),
            },
          ]
        : []),
    ],
    [
      authedProviderUser,
      uniqueInvitees,
      showBookACall,
      showScheduleSetup,
      showPaymentsSetup,
      showCalendlyClientsSetup,
      showSetupStorefront,
    ],
  );

  const addPatients = () => {
    if (willHitClientLimit()) {
      showUpgradeModal(AnalyticsPage.Home);
      return;
    }
    setIsAddPatientsModalOpen(true);
  };

  const notifications =
    authedProviderUser?.notifications?.filter(
      (notification) => !Boolean(notification.readAt),
    ) ?? [];

  // Refresh notifications when the page is loaded
  useEffect(() => {
    refreshAuthedProviderUserNotifications();
  }, []);

  const [isSelectClientModalOpen, setSelectClientModalOpen] = useState(false);
  const [isAddPatientsModalOpen, setIsAddPatientsModalOpen] = useState(false);

  const [isAddPDFModalOpen, setIsAddPDFModalOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState(false);

  const openAddOrEditTextModal = () => setIsAddOrEditTextModalOpen(true);

  const onActivityAdded = (activityType: ActivityType) => {
    return async (activityId: string) => {
      navigate('/library');
    };
  };

  const {
    data: meProviderProgramTagsData,
    refetch: refetchMeProviderProgramTags,
  } = useMeProviderProgramTagsWithCountQuery();

  const providerProgramTags =
    meProviderProgramTagsData?.meProvider?.provider?.programTags ?? [];

  const allNotifications = [...onboardingSteps, ...notifications];

  return (
    <PageContainer>
      <Transition
        show={Boolean(authedProviderUser)}
        {...defaultTransitionProps}
      >
        <div className="mb-10 flex flex-row gap-x-2">
          <div className="mr-6 flex flex-col justify-center text-caption">
            Quick Actions
          </div>
          <Button
            title="Add Clients"
            IconComponent={UserIcon}
            theme="secondary-white"
            iconPosition="left"
            className="shadow-100"
            onClick={addPatients}
          />
          {authedProviderUser?.patientDataPermission !==
            PatientDataPermission.None && (
            <>
              <Button
                title="Send Mail"
                IconComponent={MailIcon}
                theme="secondary-white"
                iconPosition="left"
                className="!text-purple-100 shadow-100"
                onClick={() => navigate('/follow-ups/new')}
              />
              <Button
                title="Start Chat"
                IconComponent={ChatIcon}
                theme="secondary-white"
                iconPosition="left"
                className="!text-blue-100 shadow-100"
                onClick={() => setSelectClientModalOpen(true)}
              />
            </>
          )}
          <AddToLibraryMenu
            buttonClassName="!text-orange-100 shadow-100"
            buttonTheme="secondary-white"
            buttonSize="medium"
            onClickAddLink={() => setIsAddLinkModalOpen(true)}
            onClickAddImage={() => setIsAddImageModalOpen(true)}
            onClickAddPDF={() => setIsAddPDFModalOpen(true)}
            onClickAddAudio={() => setIsAddAudioModalOpen(true)}
            onClickAddForm={() =>
              navigate('/library/forms/new', {
                state: {
                  fromPage: 'home',
                },
              })
            }
            onClickAddText={openAddOrEditTextModal}
            IconComponent={DocumentPlusIcon}
          />
          <Button
            title="Invoice"
            IconComponent={BanknotesIcon}
            theme="secondary-white"
            iconPosition="left"
            className="!text-green-100 shadow-100"
            onClick={() => {
              if (hasStripeIntegration) {
                navigate('/payments/new');
              } else if (isOwnerOrAdmin) {
                navigate('/integrations/stripe');
              } else {
                navigate('/payments');
              }
            }}
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="mr-10 mb-5 lg:flex lg:w-[300px] lg:flex-col">
            <div className="flex w-full flex-col items-start">
              <div className="text-subtitle-small">Upcoming sessions</div>
              {!hasCalendlyIntegration && (
                <div className="mt-7 flex flex-row text-small-caption">
                  <SparklesIcon className="relative bottom-[2px] mr-2 h-5 w-5 text-neutral-110" />
                  <div
                    onClick={() => navigate('/integrations/calendly')}
                    className="mr-1 cursor-pointer font-medium text-green-100 hover:text-green-50"
                  >
                    Set up your calendar
                  </div>
                  <div> to display sessions.</div>
                </div>
              )}
              <div className="mt-6 mb-10 flex w-full flex-wrap gap-7">
                {hasUpcomingEvents ? (
                  <>
                    {upcomingEvents?.map((upcomingEvent, index) => (
                      <UpcomingEvent
                        key={index}
                        upcomingEvent={upcomingEvent}
                        isDemo={!hasCalendlyIntegration}
                      />
                    ))}
                  </>
                ) : (
                  <div>No upcoming sessions</div>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col">
            <div className="mb-3 flex flex-row">
              <div className="font-sans text-subtitle-small">Inbox</div>
            </div>
            <div className="divide-y divide-neutral-50 rounded-lg border border-neutral-50 p-7">
              {allNotifications.map((elem, i) => {
                if (isNotification(elem)) {
                  return (
                    <NotificationItem
                      key={`notification-${elem.id}`}
                      notification={elem}
                    />
                  );
                } else {
                  return (
                    <OnboardingNotification
                      key={`onboardingNotification-${i}`}
                      step={elem}
                    />
                  );
                }
              })}
              {!Boolean(allNotifications.length) && (
                <div className="text-center text-body text-neutral-110">
                  Events related to your practice and your clients will appear
                  here.
                </div>
              )}
            </div>
          </div>
        </div>
        <SelectPatientModal
          isOpen={isSelectClientModalOpen}
          setClosed={() => setSelectClientModalOpen(false)}
        />
        <AddLinkModal
          isModalOpen={isAddLinkModalOpen}
          setClosed={() => setIsAddLinkModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Link)}
        />
        <AddPDFModal
          isModalOpen={isAddPDFModalOpen}
          setClosed={() => setIsAddPDFModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Pdf)}
        />
        <AddImageModal
          isModalOpen={isAddImageModalOpen}
          setClosed={() => setIsAddImageModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Image)}
        />
        <AddAudioModal
          isModalOpen={isAddAudioModalOpen}
          setClosed={() => setIsAddAudioModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Audio)}
        />
        <AddOrEditTextModal
          isModalOpen={isAddOrEditTextModalOpen}
          setClosed={() => setIsAddOrEditTextModalOpen(false)}
          onAdded={onActivityAdded(ActivityType.Text)}
        />
        <AddPatientsModal
          isClientsPage={false}
          isModalOpen={isAddPatientsModalOpen}
          setClosed={() => setIsAddPatientsModalOpen(false)}
          providerProgramTags={providerProgramTags}
          refetchMeProviderProgramTags={refetchMeProviderProgramTags}
          {...(showPrefilledPatientsList && {
            prefilledPatientsList: uniqueInvitees,
            isFromCalendly: true,
          })}
        />
      </Transition>
    </PageContainer>
  );
};

export default Home;

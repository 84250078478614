import { FC } from 'react';
import classNames from 'classnames';
import { SignatureUser } from '../../generated/graphql';
import {
  getColorForSignatureUserType,
  SignatureUserTypeLabel,
} from '../lib/multiSignature';

interface SignatureUserDisplayProps {
  assessmentSignatureUser: {
    orderIndex: number;
    signatureUserType: SignatureUser;
  };
  disabled?: boolean;
}

const SignatureUserDisplay: FC<SignatureUserDisplayProps> = ({
  assessmentSignatureUser,
  disabled = false,
}) => {
  const userTypeColor = getColorForSignatureUserType(
    assessmentSignatureUser.signatureUserType,
  );

  return (
    <div
      className={classNames(
        'flex h-[88px] max-w-[398px] flex-grow items-center rounded-l-xl border-l-[14px] p-4',
        !disabled ? userTypeColor.borderColor : 'border-neutral-50',
      )}
    >
      <div
        className={classNames(
          'mr-4 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md text-body text-neutral-125',
          !disabled
            ? userTypeColor.backgroundColor
            : 'bg-neutral-50 text-neutral-100',
        )}
      >
        {assessmentSignatureUser.orderIndex + 1}
      </div>
      <div
        className={classNames(
          'min-w-[330px] flex-grow rounded-md p-[10px] px-4 text-body text-neutral-125',
          !disabled
            ? userTypeColor.backgroundColor
            : 'bg-neutral-50 text-neutral-100',
        )}
      >
        {SignatureUserTypeLabel[assessmentSignatureUser.signatureUserType]}
      </div>
    </div>
  );
};

export default SignatureUserDisplay;

import { Transition } from '@headlessui/react';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import {
  UserType,
  useProgramActivitiesQuery,
  ProgramActivityDataFragment,
  useCompleteProgramActivityMutation,
} from '../../../../generated/graphql';
import Spinner from '../../../svgs/Spinner';
import {
  DEFAULT_TRANSITION_DURATION,
  defaultTransitionProps,
} from '../../../lib/animation';
import ActivityTable from '../../../components/ActivityTable';
import { useCallback, useState } from 'react';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import IllustrationMaze from '../../../svgs/IllustrationMaze';
import ProgramActivitySlideover from '../../../components/ProgramActivitySlideover';
import { debounce } from 'lodash';
import { ContentType } from '../../../lib/followUp';
import SearchAndFilters from '../../../components/SearchAndFilters';
import { doesActivityMeetFilters } from './helpers';
import DownloadAppBanner from '../../../components/PatientApp/DownloadAppBanner';
import ProgramActivityCard from '../../../components/ProgramActivityCard';
import PageContainer from '../../../components/Containers/PageContainer';

const PatientLibrary = () => {
  const { authedPatient } = usePatientAuth();

  const {
    data: programActivitiesData,
    error: programActivitiesError,
    loading: programActivitiesLoading,
    refetch: refetchProgramActivities,
  } = useProgramActivitiesQuery({
    variables: {
      programId: authedPatient.programInstanceId,
    },
  });

  const [completeProgramActivity] = useCompleteProgramActivityMutation();

  const programActivities = programActivitiesData?.programActivities;

  const [selectedProgramActivity, setSelectedProgramActivity] =
    useState<ProgramActivityDataFragment>();
  const [isProgramActivitySlideoverOpen, setIsProgramActivitySlideoverOpen] =
    useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermValue, setSearchTermValue] = useState('');

  const clearSearchTerm = () => {
    setSearchTermValue('');
    setSearchTerm('');
  };

  const [contentTypeFilter, setContentTypeFilter] =
    useState<ContentType | null>(null);

  const clearFilterTerm = () => setContentTypeFilter(null);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermValue(e.target.value);
    debouncedSearch(e);
  };

  const debouncedSearch = useCallback(
    debounce(
      (e) => setSearchTerm(e.target.value.toLowerCase().trim()),
      DEFAULT_TRANSITION_DURATION,
    ),
    [],
  );

  const providerActivitiesList = programActivities?.filter((programActivity) =>
    doesActivityMeetFilters(programActivity, {
      contentTypeFilter,
      searchTerm,
    }),
  );

  const handleOnClickProgramActivity = (
    programActivity: ProgramActivityDataFragment,
  ) => {
    setSelectedProgramActivity(programActivity);
    setIsProgramActivitySlideoverOpen(true);
  };

  return (
    <div className="w-full md:h-[calc(100vh-var(--top-nav-height))] md:overflow-y-hidden">
      <DownloadAppBanner className="sm:hidden" />
      <PageContainer noPadding containerClassName="md:px-16 px-6 py-8">
        <div className="mb-0 flex flex-row justify-between md:mb-4">
          <div className="mb-4 font-serif text-subtitle-small font-light md:text-subtitle">
            Library
          </div>
        </div>
        <div className="mb-4 flex w-full flex-row items-center justify-between md:mb-0">
          <SearchAndFilters
            searchTermValue={searchTermValue}
            clearSearchTerm={clearSearchTerm}
            clearFilterTerm={clearFilterTerm}
            onChangeSearch={onChangeSearch}
            contentTypeFilter={contentTypeFilter}
            setContentTypeFilter={setContentTypeFilter}
            patientMode
          />
        </div>
        {programActivitiesLoading && <Spinner className="mx-auto mt-8" />}
        <Transition
          show={Boolean(
            programActivities &&
              !programActivitiesError &&
              !programActivitiesLoading,
          )}
          {...defaultTransitionProps}
        >
          <div className="hidden md:flex md:flex-col">
            {Boolean(providerActivitiesList?.length) && (
              <ActivityTable
                programActivities={providerActivitiesList}
                programActivitiesLoading={programActivitiesLoading}
                onClickProgramActivity={handleOnClickProgramActivity}
                patientResourcesMode
              />
            )}
          </div>
          <div className="flex-col md:hidden">
            {Boolean(providerActivitiesList?.length) &&
              providerActivitiesList.map((programActivity, index) => (
                <ProgramActivityCard
                  key={`programActivityCard_${index}`}
                  isSmallStyle
                  userType={UserType.Patient}
                  isLastCard={index === providerActivitiesList.length - 1}
                  programActivity={programActivity}
                  onClickProgramActivity={handleOnClickProgramActivity}
                />
              ))}
          </div>
          {!Boolean(providerActivitiesList?.length) && (
            <EmptyProfileTab
              IconComponent={IllustrationMaze}
              iconClassName={'text-green-125'}
              titleText={`${authedPatient?.firstName ?? ''}'s Resources`}
              subTitleText={`All the activities and resources you received from your provider will appear here.`}
            />
          )}
        </Transition>

        <ProgramActivitySlideover
          isOpen={isProgramActivitySlideoverOpen}
          onClose={() => setIsProgramActivitySlideoverOpen(false)}
          programActivity={selectedProgramActivity}
          userType={UserType.Patient}
          onMarkComplete={async () => {
            if (selectedProgramActivity) {
              await completeProgramActivity({
                variables: {
                  input: {
                    programActivityId: selectedProgramActivity.id,
                  },
                },
              });

              refetchProgramActivities();
              setIsProgramActivitySlideoverOpen(false);
            }
          }}
          onDismiss={async () => {
            if (selectedProgramActivity) {
              setIsProgramActivitySlideoverOpen(false);
            }
          }}
          onSubmitPdf={() => {
            refetchProgramActivities();
            setIsProgramActivitySlideoverOpen(false);
          }}
        />
      </PageContainer>
    </div>
  );
};

export default PatientLibrary;

import classNames from 'classnames';
import { SparklesIcon } from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';

import hcLogo from '../../../assets/images/logo/logo-green-xl.png';
import { UserType, useMePatientQuery } from '../../../generated/graphql';
import SettingsMenu from '../SettingsMenu';
import {
  AnalyticsComponent,
  CommonAnalyticsEvent,
  trackPatientEvent,
} from '../../../lib/analytics';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';
import UpdateButton from '../UpdateButton';
import { getFirstName } from '../../lib/copy';
import Config from '../../../lib/config';

type PatientHeaderProps = {
  children?: React.ReactNode;
};

function TopNav({ navigation }) {
  const { pathname } = useLocation();

  return (
    <nav
      className="flex flex-row items-center justify-start"
      aria-label="Navigation items"
    >
      {navigation.map((navItem) => {
        const { name, path } = navItem;
        const isActiveTab = pathname.startsWith(path);
        return (
          <Link
            key={name}
            to={path}
            className={classNames(
              'flex items-center justify-center px-1.5 text-caption md:px-3',
              isActiveTab
                ? 'text-green-150'
                : 'text-neutral-110 hover:text-green-150',
            )}
            onClick={() => {
              trackPatientEvent(CommonAnalyticsEvent.ButtonClicked, {
                buttonName: name,
                originComponent: AnalyticsComponent.MainHeader,
              });
            }}
          >
            {name}
          </Link>
        );
      })}
    </nav>
  );
}

export default function PatientHeader({ children }: PatientHeaderProps) {
  const isMobileDevice = useIsMobileDevice();

  const { data: mePatientData } = useMePatientQuery();
  const mePatient = mePatientData?.mePatient;

  const navigation = [
    {
      name: 'Home',
      path: '/client/home',
    },
    {
      name: 'Mail',
      path: '/client/follow-ups',
    },
    {
      name: 'Chat',
      path: '/client/messages',
    },
    ...(mePatient?.hasCalendlyIntegration
      ? [
          {
            name: 'Sessions',
            path: '/client/sessions',
          },
        ]
      : []),
    {
      name: 'Library',
      path: '/client/library',
    },
  ];

  const providerFirstName = getFirstName(mePatient?.providerName);
  const providerStorefrontLink = `${Config.REACT_APP_WEB_APP_URL}/p/${mePatient?.providerStorefrontSlug}`;
  const REFERRAL_MAIL_TEXT = `mailto:?subject=Connecting%20you%20with%20${mePatient?.providerName}&body=Hello%20there%2C%0A%0AI%20wanted%20to%20share%20something%20special%20with%20you.%20Working%20with%20${providerFirstName}%20has%20been%20an%20incredibly%20positive%20and%20transformative%20experience%20for%20me.%0A%0AIf%20you%E2%80%99ve%20been%20considering%20guidance%20or%20support%2C%20${providerFirstName}%20comes%20with%20my%20highest%20recommendation.%20To%20learn%20more%20and%20connect%20with%20them%2C%20here%E2%80%99s%20a%20link%20to%20their%20booking%20page%3A%20${providerStorefrontLink}%0A%0ABest%20wishes%20on%20your%20journey%2C%0A${mePatient?.firstName}`;

  return (
    <header
      className={classNames(
        'fixed z-50 flex h-[var(--top-nav-height)] w-full flex-row items-center justify-between px-4 md:px-8',
        'border-b border-neutral-50 bg-neutral-25 text-caption font-medium text-green-150',
      )}
    >
      <div className="flex flex-row items-center justify-start">
        <Link
          to="/client/home"
          className="mr-2 flex h-full items-center justify-center md:mr-5"
          onClick={() => {
            trackPatientEvent(CommonAnalyticsEvent.ButtonClicked, {
              buttonName: 'Logo',
              originComponent: AnalyticsComponent.MainHeader,
            });
          }}
        >
          <img src={hcLogo} className="min-w-9 h-9" alt="logo" />
        </Link>
        {!isMobileDevice && <TopNav navigation={navigation} />}
      </div>
      <div className="flex flex-row items-center justify-end">
        {!isMobileDevice && (
          <UpdateButton
            onClick={() => {
              window.open(REFERRAL_MAIL_TEXT, '_blank');
            }}
            IconComponent={SparklesIcon}
            theme="green"
            text={`Refer a friend to ${providerFirstName}`}
          />
        )}
        {mePatient && (
          <SettingsMenu
            name={mePatient?.name}
            email={mePatient?.email}
            imageUrl={mePatient?.profileImageMedia?.url}
            userType={UserType.Patient}
            isMobileDevice={isMobileDevice}
            showPatientSessions={mePatient?.hasCalendlyIntegration}
          />
        )}
      </div>
    </header>
  );
}

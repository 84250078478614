import { FC, useEffect, useState } from 'react';

import {
  ActivityType,
  AssessmentSchemaType,
  ProgramActivityDataFragment,
  UserType,
  useProgramAssessmentLazyQuery,
} from '../../generated/graphql';
import Slideover, { SLIDEOVER_TRANSITION_DURATION } from './Slideover';
import SlideoverSection from './SlideoverSection';
import { ProgramActivityStatus } from './ProgramActivityStatus';
import {
  programActivityOpen,
  programActivityTitle,
} from '../lib/programActivity';
import { ProgramActivityThumbnail } from './ProgramActivityThumbnail';
import { getTimeStamp } from '../lib/time';
import Button from './Button';
import AudioPlayer from './AudioPlayer';
import ResponsesSection from './Assessments/ResponsesSection';
import ScoreSection from './Assessments/ScoreSection';
import toast from 'react-hot-toast';
import ToastAlert from './ToastAlert';
import InterpretationSection from './Assessments/InterpretationSection';
import QuestionsSection from './Assessments/QuestionsSection';
import ResponseOptionsSection from './Assessments/ResponseOptionsSection';
import AddOrEditTextModal from './Modals/AddOrEditTextModal';
import {
  AnalyticsComponent,
  trackProviderComponentViewedEvent,
} from '../../lib/analytics';
import PdfSubmissionForm from './PdfSubmissionForm';

const ProgramActivityTitle: FC<{
  programActivity: ProgramActivityDataFragment;
  subtitle?: string;
}> = ({ programActivity, subtitle }) => {
  const title = programActivityTitle(programActivity);

  return (
    <div className="flex items-center self-start pt-2">
      <ProgramActivityThumbnail
        programActivity={programActivity}
        className="mr-4 h-[60px] w-[60px]"
      />
      <div className="flex-col text-green-150">
        <div className="pr-4 text-big-category font-medium line-clamp-2">
          {title}
        </div>
        <div className="truncate text-caption text-neutral-125">
          {subtitle ? subtitle : getTimeStamp(programActivity.sentAt, false)}
        </div>
      </div>
    </div>
  );
};

/*
    Type-agnostic Slideover for any existing ProgramActivity entity
*/
const ProgramActivitySlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  programActivity?: ProgramActivityDataFragment;
  patientFirstName?: string;
  onAssessmentOpen?: () => void;
  onMarkComplete?: () => void;
  onDismiss?: () => void;
  onSubmitPdf?: () => void;
  userType?: UserType;
}> = ({
  onClose,
  isOpen,
  programActivity,
  patientFirstName,
  onAssessmentOpen,
  onMarkComplete,
  onDismiss,
  onSubmitPdf,
  userType = UserType.ProviderUser,
}) => {
  const [
    getProgramAssessment,
    {
      data: programAssessmentData,
      error: programAssessmentError,
      loading: isLoadingProgramAssessment,
    },
  ] = useProgramAssessmentLazyQuery();

  useEffect(() => {
    if (programActivity) {
      if (programActivity.programAssessment?.id) {
        // Pull full assessment data if this is an assessment
        getProgramAssessment({
          variables: {
            programAssessmentId: programActivity.programAssessment.id,
          },
        });
      }
      trackProviderComponentViewedEvent(
        AnalyticsComponent.ProgramActivitySlideover,
        undefined,
        {
          activityType: programActivity.activityType,
        },
      );
    }
  }, [programActivity]);

  // Only set programAssessment if it matches underlying programActivity
  // (Essentially clears it out if the programActivity changes)
  const programAssessment =
    programActivity?.programAssessment?.id ===
    programAssessmentData?.programAssessment?.id
      ? programAssessmentData?.programAssessment
      : undefined;

  useEffect(() => {
    if (programAssessmentError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch assessment details."
          level="error"
        />
      ));
    }
  }, [programAssessmentError]);

  // Assessment details sub-slideover
  const [shouldShowAssessmentDetails, setShouldShowAssessmentDetails] =
    useState<boolean>(false);

  const defaultOnAssessmentOpen = () => {
    setShouldShowAssessmentDetails(true);
  };

  // Text view-only modal
  const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
    useState<boolean>(false);

  const onTextOpen = () => {
    setIsAddOrEditTextModalOpen(true);
  };

  let onOpen, openText, secondaryOnOpen, secondaryOpenText;
  if (programActivity) {
    ({ onOpen, openText, secondaryOnOpen, secondaryOpenText } =
      programActivityOpen(
        programActivity,
        onAssessmentOpen ?? defaultOnAssessmentOpen,
        onTextOpen,
      ));
  }

  // Clear sub-slideover when main slideover closes
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setShouldShowAssessmentDetails(false);
      }, SLIDEOVER_TRANSITION_DURATION);
    }
  }, [isOpen]);

  const allowCompletion = (programActivity: ProgramActivityDataFragment) =>
    onMarkComplete &&
    programActivity?.activityType !== ActivityType.Assessment &&
    programActivity?.activityType !== ActivityType.PdfForm &&
    programActivity?.activityType !== ActivityType.Esign &&
    programActivity?.isCompletable &&
    !programActivity?.completedAt;

  const allowDismissal = (programActivity: ProgramActivityDataFragment) =>
    onDismiss &&
    programActivity?.isCompletable &&
    !programActivity?.completedAt &&
    !programActivity?.dismissedAt;

  const isPdfFormWithSubmission =
    programActivity?.activityType === ActivityType.PdfForm &&
    programActivity?.pdfSubmission;

  const hasAudioAndOnOpen = !programActivity?.activityAudio && onOpen;

  const showMultiuseSlideoverSection =
    isPdfFormWithSubmission ||
    hasAudioAndOnOpen ||
    allowCompletion(programActivity) ||
    allowDismissal(programActivity);

  return (
    <Slideover
      isOpen={isOpen}
      onClose={onClose}
      showContent={Boolean(programActivity) && !isLoadingProgramAssessment}
      titleComponent={
        programActivity && (
          <ProgramActivityTitle
            programActivity={programActivity}
            subtitle={
              shouldShowAssessmentDetails && programAssessment
                ? programAssessment.assessment.name
                : undefined
            }
          />
        )
      }
      backButtonActive={shouldShowAssessmentDetails}
      onBackButtonClick={() => setShouldShowAssessmentDetails(false)}
    >
      {/* Main slideover */}
      {programActivity && !shouldShowAssessmentDetails && (
        <div className="pb-6">
          {!Boolean(programAssessment?.completedAt) && (
            // Don't show when the assessment/form is completed,
            // completion is implied from the assessment-specific score/responses sections.
            <SlideoverSection title="Status">
              <ProgramActivityStatus
                programActivity={programActivity}
                className="text-small-caption"
                userType={userType}
              />
            </SlideoverSection>
          )}

          {/* Completed Assessment/Form specific sections */}
          {programAssessment && (
            <>
              {programAssessment.completedAt &&
                programAssessment.assessment.schemaType !==
                  AssessmentSchemaType.Custom && (
                  <ScoreSection
                    assessmentType={programAssessment.assessment.type}
                    title="Score"
                    completedAssessment={programAssessment}
                  />
                )}
              {Boolean(programAssessment.additionalThoughts) && (
                <SlideoverSection
                  title={
                    patientFirstName
                      ? `Note from ${patientFirstName}`
                      : 'Client note'
                  }
                  textContent={`"${programAssessment.additionalThoughts}"`}
                />
              )}
              <ResponsesSection
                title="Responses"
                questionFrame={
                  programAssessment.assessment.questionFrame ?? undefined
                }
                programAssessment={programAssessment}
              />
            </>
          )}

          {programActivity.providerNote && (
            <SlideoverSection
              title="Guidance"
              textContent={programActivity.providerNote}
            />
          )}

          {/* Assessment/Form specific description. In spec, goes after Guidance */}
          {programAssessment && (
            <SlideoverSection
              title="About"
              textContent={programAssessment.assessment.description}
            />
          )}

          {/* Audio specific sections */}
          {programActivity.activityAudio && (
            <SlideoverSection title="Audio">
              <AudioPlayer
                playerId={
                  programActivity.activityAudio.id ?? 'provider-activity-player'
                }
                mediaUrl={programActivity.activityAudio.media.url}
                duration={programActivity.activityAudio.duration}
                triggerStop={!isOpen}
              />
            </SlideoverSection>
          )}

          {/* Buttons section */}
          {showMultiuseSlideoverSection && (
            <SlideoverSection className="gap-y-3">
              {isPdfFormWithSubmission ? (
                <PdfSubmissionForm
                  linkToken={programActivity?.pdfSubmission?.linkToken}
                  onSuccess={onSubmitPdf}
                />
              ) : (
                <>
                  {hasAudioAndOnOpen && (
                    <>
                      <Button
                        title={openText}
                        onClick={onOpen}
                        className="w-full"
                      />
                      {secondaryOnOpen && (
                        <Button
                          theme="secondary-white"
                          title={secondaryOpenText}
                          onClick={secondaryOnOpen}
                          className="w-full"
                        />
                      )}
                    </>
                  )}
                  {allowCompletion(programActivity) && (
                    <Button
                      theme={onOpen ? 'secondary' : 'primary'}
                      title="Mark complete"
                      onClick={onMarkComplete}
                      className="w-full"
                    />
                  )}
                  {allowDismissal(programActivity) && (
                    <Button
                      theme="secondary"
                      title="Revisit later"
                      onClick={onDismiss}
                      className="w-full"
                    />
                  )}
                </>
              )}
            </SlideoverSection>
          )}
        </div>
      )}

      {/* Assessment details sub-slideover */}
      {programActivity && programAssessment && shouldShowAssessmentDetails && (
        <div className="pb-6">
          {programAssessment.assessment.instructions && (
            <SlideoverSection
              title="Instructions"
              textContent={programAssessment.assessment.instructions}
            />
          )}
          <InterpretationSection assessment={programAssessment.assessment} />
          <ResponseOptionsSection
            questions={programAssessment.assessment.questions}
          />
          <QuestionsSection
            questionFrame={programAssessment.assessment.questionFrame}
            questions={programAssessment.assessment.questions}
          />
        </div>
      )}
      <AddOrEditTextModal
        isModalOpen={isAddOrEditTextModalOpen}
        setClosed={() => setIsAddOrEditTextModalOpen(false)}
        selectedActivityText={programActivity?.activityText}
        viewOnly
      />
    </Slideover>
  );
};

export default ProgramActivitySlideover;

import { FC, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Loading from './Loading';
import { useMeProviderSignUpConfigLazyQuery } from '../../generated/graphql';

const AuthCallback: FC = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently, handleRedirectCallback, logout } = useAuth0();
  const { saveToken } = useAuth();

  const [meProviderSignUpConfigQuery] = useMeProviderSignUpConfigLazyQuery();

  const effectRan = useRef(false);

  useEffect(() => {
    // This check is critical to prevent React's strict mode from causing the effect to run twice.
    // The second run will always fail.
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    (async () => {
      let from = '/';
      try {
        const { appState } = await handleRedirectCallback();
        if (appState?.from) {
          from = appState.from;
        }

        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        saveToken(accessToken);

        const { data } = await meProviderSignUpConfigQuery();
        if (data?.meProvider?.signUpConfig?.isCommunityHub) {
          navigate('/community', { replace: true });
        } else {
          navigate(from, { replace: true });
        }
      } catch (error) {
        console.error('Error handling redirect callback:', error);
        await logout();
      }
    })();
  }, []);

  return <Loading />;
};

export default AuthCallback;

import { FC } from 'react';

import { ProviderAssessmentsWithoutScheduleQuery } from '../../../../generated/graphql';
import {
  isFreeTextAssessmentQuestion,
  isMultiSelectAssessmentQuestion,
  isMultipleChoiceAssessmentQuestion,
  isMultipleChoiceScoredAssessmentQuestion,
  isRichTextAssessmentQuestion,
  isSignatureAssessmentQuestion,
  isMultiSignatureAssessmentQuestion,
  isStatementAssessmentQuestion,
  isInitialsAssessmentQuestion,
} from '../../../lib/assessments';
import SlideoverSection from '../../SlideoverSection';
import QuestionItem from '../QuestionItem';

export enum QuestionSubscale {
  MultipleChoice = 'Multiple choice',
  MultipleSelect = 'Multiple select',
  Text = 'Text',
  Initials = 'Client initials',
  Signature = 'Client signature',
  MultiSignature = 'Collaborative signature',
  Statement = 'Statement (read-only)',
}

const QuestionsSection: FC<{
  questions?:
    | ProviderAssessmentsWithoutScheduleQuery['assessments'][number]['questions']
    | ProviderAssessmentsWithoutScheduleQuery['forms'][number]['questions'];
  questionFrame?: string | null;
  title?: string;
  isActivityForm?: boolean;
}> = ({
  questions,
  questionFrame,
  title = 'Questions',
  isActivityForm = false,
}) => {
  return (
    <SlideoverSection title={title}>
      {questionFrame && (
        <div className="mb-4 font-sans text-caption text-neutral-125">
          {questionFrame}
        </div>
      )}
      {questions?.map((question, index) => {
        if (isMultipleChoiceScoredAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.MultipleChoice}
              isForm={isActivityForm}
            />
          );
        }
        if (
          isMultipleChoiceAssessmentQuestion(question) ||
          isMultiSelectAssessmentQuestion(question)
        ) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={
                isMultiSelectAssessmentQuestion(question)
                  ? QuestionSubscale.MultipleSelect
                  : QuestionSubscale.MultipleChoice
              }
              answerOptions={question.answerOptions.map(
                (answerOption) => answerOption.answerOption,
              )}
              isForm={isActivityForm}
              questionType={question.questionType}
            />
          );
        }
        if (
          isFreeTextAssessmentQuestion(question) ||
          isRichTextAssessmentQuestion(question)
        ) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.Text}
              isForm={isActivityForm}
            />
          );
        }
        if (isInitialsAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.Initials}
              isForm={isActivityForm}
            />
          );
        }
        if (isSignatureAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.Signature}
              isForm={isActivityForm}
            />
          );
        }
        if (isMultiSignatureAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.MultiSignature}
              assessmentSignatureUsers={question.assessmentSignatureUsers}
              isForm={isActivityForm}
            />
          );
        }
        if (isStatementAssessmentQuestion(question)) {
          return (
            <QuestionItem
              key={index}
              questionIndex={index}
              question={question.question}
              subscale={QuestionSubscale.Statement}
              isForm={isActivityForm}
            />
          );
        }
        return null;
      })}
    </SlideoverSection>
  );
};

export default QuestionsSection;

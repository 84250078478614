import { FC } from 'react';

import _ from 'lodash';
import { marked } from 'marked';
import classNames from 'classnames';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';

import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import {
  AssessmentQuestionType,
  FreeTextAssessmentQuestion,
  RichTextAssessmentQuestion,
  InitialsAssessmentQuestion,
  SignatureAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  MultipleChoiceAssessmentQuestion,
  ProgramAssessmentSignatureUserDataFragment,
} from '../../../../../generated/graphql';

import SignatureFlagName from '../../../../svgs/SignatureFlagName';

import { formatCurrentDate } from '../../../../lib/time';
import markdownToTxt from '../../../../lib/markdown-to-txt';
import { ProgramAssessmentQuestion } from '../../../../lib/program-assessment';

import Editor from '../../../../components/Editor';
import Checkbox from '../../../../components/Checkbox';
import InputGroup from '../../../../components/InputGroup';
import CompletedSignature from '../../../../components/CompletedSignature';

import { AssessmentFormValues } from './helpers';

const QuestionField: FC<{ question: string }> = ({ question }) => {
  return (
    <div className="mb-3 text-body">
      <div
        className={classNames('editor text-green-150')}
        dangerouslySetInnerHTML={{ __html: marked(question) }}
      />
    </div>
  );
};

const Question: FC<{
  question: ProgramAssessmentQuestion;
  idx: number;
  register: UseFormRegister<AssessmentFormValues>;
  control: Control<AssessmentFormValues, unknown>;
  watch: UseFormWatch<AssessmentFormValues>;
  patientAssessmentSignatureUserId?: string;
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
  isMobileDevice: boolean;
  patientName: {
    fullName: string;
    initials: string;
  };
  formIsReadOnly: boolean;
}> = ({
  question,
  register,
  idx,
  control,
  watch,
  programAssessmentSignatureUsers,
  currentSigner,
  isMobileDevice,
  patientName,
  formIsReadOnly = false,
}) => {
  const signatureNamePath: `answers.${number}.signatureName` = `answers.${idx}.signatureName`;
  const signatureNameWatch = watch(signatureNamePath);

  const { fullName, initials } = patientName;
  const isValidSignature = signatureNameWatch === fullName;

  switch (question.questionType) {
    case AssessmentQuestionType.FreeText:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <InputGroup
            key={markdownToTxt(question.question)}
            {...register(`answers.${idx}.value`, {
              required: question.isRequired,
            })}
            label={question.question}
            labelHidden
            inputSize="extra-small"
            containerClassName="w-full md:w-[398px]"
            readOnly={formIsReadOnly}
          />
        </div>
      );
    case AssessmentQuestionType.RichText:
      question = question as RichTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <Controller
            control={control}
            name={`answers.${idx}.value`}
            rules={{
              required: {
                value: question.isRequired,
                message: 'Please enter your response',
              },
            }}
            render={({ field }) => {
              const { onChange, value } = field;
              return (
                <Editor
                  placeholder="Enter your response..."
                  className="text-neutral-150"
                  initialContentMarkdown={value}
                  allowLinks={false}
                  onChange={(editorState) => {
                    editorState.read(() => {
                      onChange($convertToMarkdownString(TRANSFORMERS));
                    });
                  }}
                  onError={(error: Error) => {
                    console.error(error);
                  }}
                  readOnly={formIsReadOnly}
                />
              );
            }}
          />
        </div>
      );
    case AssessmentQuestionType.Statement:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div className="mt-6">
          <QuestionField question={question.question} />
        </div>
      );

    case AssessmentQuestionType.Initials:
      question = question as InitialsAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <div className={signatureNameWatch && 'h-2'} />
          {!signatureNameWatch && (
            <div className="text-caption font-medium">
              {isMobileDevice ? 'Tap' : 'Click'} the box to add your initials
            </div>
          )}
          <Controller
            name={signatureNamePath}
            control={control}
            rules={{
              required: { value: true, message: 'Initials are required' },
              validate: (value) => value === initials,
            }}
            render={({ field }) => (
              <div className="flex flex-row items-center justify-start">
                {!field.value ? (
                  <InputGroup
                    IconLeft={SignatureFlagName}
                    iconLeftClassName="h-5 w-5 -ml-1"
                    containerClassName="mt-2 mb-1 w-[120px]"
                    className="cursor-pointer rounded-lg"
                    inputSize="extra-small"
                    disabled={field.value === initials}
                    value={field.value || ''}
                    onClick={() => !formIsReadOnly && field.onChange(initials)}
                  />
                ) : (
                  <CompletedSignature
                    signatureName={signatureNameWatch}
                    nowFormattedTimestamp={formatCurrentDate()}
                    hideNameAndRole
                  />
                )}
              </div>
            )}
          />
        </div>
      );

    case AssessmentQuestionType.Signature:
      question = question as SignatureAssessmentQuestion;

      return (
        <div>
          <QuestionField question={question.question} />
          <div
            className={classNames(
              'mb-2 mt-6 text-caption font-medium',
              isValidSignature && 'hidden',
            )}
          >
            Type your name to sign electronically
          </div>
          <InputGroup
            IconLeft={SignatureFlagName}
            containerClassName={classNames(
              'mt-3 mb-2 w-full md:w-[398px]',
              isValidSignature && 'hidden',
            )}
            className="rounded-lg pl-12"
            readOnly={formIsReadOnly}
            {...register(signatureNamePath, {
              required: { value: true, message: 'Signature is required' },
              validate: {
                nameMatch: (value) => {
                  if (value !== fullName) {
                    return 'Signature must match your full name';
                  }
                  return true;
                },
              },
            })}
          />
          {signatureNameWatch && (
            <CompletedSignature
              signatureName={signatureNameWatch}
              nowFormattedTimestamp={formatCurrentDate()}
              hideNameAndRole
              className="mt-2"
              hideDate={!isValidSignature}
            />
          )}
        </div>
      );

    case AssessmentQuestionType.MultiSignature:
      question = question as MultiSignatureAssessmentQuestion;

      const assessmentSignatureUsersIds = question.assessmentSignatureUsers.map(
        ({ id }) => id,
      );

      return (
        <div>
          <QuestionField question={question.question} />
          <div>
            {programAssessmentSignatureUsers
              .sort(
                (a, b) =>
                  a.assessmentSignatureUser.orderIndex -
                  b.assessmentSignatureUser.orderIndex,
              )
              .filter(({ assessmentSignatureUser }) =>
                assessmentSignatureUsersIds.includes(
                  assessmentSignatureUser.id,
                ),
              )
              .map((programAssessmentSignatureUser) => {
                const { id, signedAt, signatureName, signatureRole } =
                  programAssessmentSignatureUser;
                const isCurrentSigner = currentSigner?.id === id;

                return (
                  <div key={id}>
                    {!isCurrentSigner && signedAt && (
                      <CompletedSignature
                        signatureName={signatureName}
                        signatureRole={signatureRole}
                        programAssessmentSignatureUser={
                          programAssessmentSignatureUser
                        }
                        className="my-4"
                      />
                    )}
                    {isCurrentSigner && (
                      <>
                        <div
                          className={classNames(
                            'mb-2 mt-6 text-caption font-medium',
                            isValidSignature && 'hidden',
                          )}
                        >
                          Type your name to sign electronically
                        </div>
                        <InputGroup
                          IconLeft={SignatureFlagName}
                          className="rounded-lg pl-12"
                          containerClassName={classNames(
                            'my-3 w-full md:w-[398px]',
                            isValidSignature && 'hidden',
                          )}
                          readOnly={formIsReadOnly}
                          {...register(signatureNamePath, {
                            required: {
                              value: true,
                              message: 'Signature is required',
                            },
                            validate: {
                              nameMatch: (value) => {
                                if (value !== fullName) {
                                  return 'Signature must match your full name';
                                }
                                return true;
                              },
                            },
                          })}
                        />
                        {signatureNameWatch && (
                          <CompletedSignature
                            signatureName={signatureNameWatch}
                            nowFormattedTimestamp={formatCurrentDate()}
                            hideNameAndRole
                            className="mb-0"
                            hideDate={!isValidSignature}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      );

    case AssessmentQuestionType.MultipleChoiceScored:
    case AssessmentQuestionType.MultipleChoice:
      question = question as MultipleChoiceAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <Controller
                key={index}
                name={`answers.${idx}.value`}
                control={control}
                rules={{ required: question.isRequired }}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id={`answer-${idx}-${index}`}
                    type="radio"
                    className="mb-2"
                    value={answerOption}
                    checked={value === answerOption}
                    readOnly={formIsReadOnly}
                    onChange={() => {
                      onChange(answerOption);
                    }}
                    labelContent={
                      <div className="ml-2 text-caption">{answerOption}</div>
                    }
                  />
                )}
              />
            );
          })}
        </div>
      );

    case AssessmentQuestionType.MultiSelect:
      question = question as MultiSelectAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          {question.answerOptions.map(({ answerOption }, index) => {
            return (
              <div key={index} className="mb-2">
                <Controller
                  name={`answers.${idx}.values`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={`answer-${idx}-${index}`}
                      value={answerOption}
                      checked={value && value.includes(answerOption)}
                      readOnly={formIsReadOnly}
                      onChange={(e) => {
                        if (!value) {
                          onChange([answerOption]);
                        } else {
                          const newValue = e.target.checked
                            ? value.concat([answerOption])
                            : _.without(value, answerOption);
                          onChange(newValue);
                        }
                      }}
                      labelContent={
                        <div className="ml-2 text-caption">{answerOption}</div>
                      }
                    />
                  )}
                />
              </div>
            );
          })}
        </div>
      );
  }

  return <div></div>;
};

export default Question;

import {
  ActivityFileCompletionType,
  ActivityType,
  AssessmentSchemaType,
  ProgramActivityDataFragment,
} from '../../generated/graphql';

export const FILE_ACTIVITY_TYPES = [
  ActivityType.Pdf,
  ActivityType.PdfForm,
  ActivityType.Esign,
];

export const activityTypeDisplay = (
  activity: ProgramActivityDataFragment,
): string => {
  switch (activity.activityType) {
    case ActivityType.Action:
      return 'Action';
    case ActivityType.Assessment:
      return activity.programAssessment?.assessment?.schemaType ===
        AssessmentSchemaType.Custom
        ? 'Form'
        : 'Assessment';
    case ActivityType.Audio:
      return 'Audio';
    case ActivityType.Esign:
      return 'E-signature';
    case ActivityType.Image:
      return 'Image';
    case ActivityType.Link:
      return 'Link';
    case ActivityType.Pdf:
    case ActivityType.PdfForm:
      return 'File';
    case ActivityType.Text:
      return 'Text';
    default:
      return '';
  }
};

export const activityCompletionTypeFromActivityType = (
  activityType: ActivityType,
): ActivityFileCompletionType => {
  switch (activityType) {
    case ActivityType.Pdf:
      return ActivityFileCompletionType.View;
    case ActivityType.PdfForm:
      return ActivityFileCompletionType.Upload;
    case ActivityType.Esign:
      return ActivityFileCompletionType.Esign;
  }
};

export const activityFileCompletionTypeDisplay = (
  activityFileCompletionType: ActivityFileCompletionType,
): string => {
  switch (activityFileCompletionType) {
    case ActivityFileCompletionType.View:
      return 'View';
    case ActivityFileCompletionType.Upload:
      return 'Fill out & Return';
    case ActivityFileCompletionType.Esign:
      return 'E-sign';
    default:
      return '';
  }
};

import React from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import Avatar from '../../components/Avatar';
import { PatientNameDataFragment } from '../../../generated/graphql';
import classNames from 'classnames';

interface PatientPillProps {
  patient: PatientNameDataFragment;
  onDelete?: (patient: PatientNameDataFragment) => void;
  className?: string;
}

const PatientPill: React.FC<PatientPillProps> = ({
  patient,
  onDelete,
  className,
}) => {
  return (
    <li
      className={classNames(
        'relative flex flex-row gap-x-2 rounded-full bg-neutral-50 py-2 px-4',
        className,
      )}
    >
      <Avatar
        size="small"
        name={patient.name}
        imageUrl={patient?.profileImageMedia?.url}
      />
      <div className="relative top-[2px]">{patient.name}</div>
      {onDelete && (
        <div onClick={() => onDelete(patient)}>
          <TrashIcon
            className={
              'h-6 w-6 cursor-pointer rounded-full bg-white p-1 text-red-100 hover:bg-red-100 hover:text-white'
            }
          />
        </div>
      )}
    </li>
  );
};

export default PatientPill;

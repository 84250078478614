import { Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import debounce from 'lodash.debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Transition as HistoryTransition } from 'history';

import { usePatientProfileContext } from '..';
import {
  ProgramNoteDataFragment,
  ProgramNotesQuery,
  useCreateProgramNoteMutation,
  useProgramNotesQuery,
  useUpdateProgramNoteMutation,
} from '../../../../generated/graphql';
import InputGroup from '../../../components/InputGroup';
import UnsavedChangesModal from '../../../components/Modals/UnsavedChangesModal';
import ToastAlert from '../../../components/ToastAlert';

import {
  defaultTransitionProps,
  DEFAULT_TRANSITION_DURATION,
} from '../../../lib/animation';
import CloseX from '../../../svgs/CloseX';
import IllustrationPencil from '../../../svgs/IllustrationPencil';

import Search from '../../../svgs/Search';
import Spinner from '../../../svgs/Spinner';
import markdownToTxt from '../../../lib/markdown-to-txt';
import IconButton from '../../../components/IconButton';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import { useAuth } from '../../../../contexts/AuthContext';
import NotePill from './NotePill';
import NoteActionsMenu from './NoteActionsMenu';

type ProgramNotes = ProgramNotesQuery['programNotes'];
type ProgramNote = ProgramNotes[number];
const NON_WORD_REGEX = /\W/;

const Notes = () => {
  const navigate = useNavigate();
  const params = useParams();
  const programId = params.programId!;
  const programNoteId = params.programNoteId;
  const {
    patient,
    refetchPinnedNotes,
    isNoteActionsMenuOpen,
    setIsNoteActionsMenuOpen,
    hasPinnedNotes,
    setIsPinnedNotesPanelOpen,
  } = usePatientProfileContext();

  const [selectedProgramNote, setSelectedProgramNote] = useState<
    ProgramNote | undefined
  >();

  const { authedProviderUser } = useAuth();

  const [navTransition, setNavTransition] = useState<HistoryTransition>();
  const [hasLoadedProgramNotes, setHasLoadedProgramNotes] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermValue, setSearchTermValue] = useState('');
  const [programNotesSearch, setProgramNotesSearch] = useState<ProgramNotes>(
    [],
  );

  const {
    data: programNotesData,
    error: programNotesError,
    loading: programNotesLoading,
    refetch: refetchProgramNotes,
  } = useProgramNotesQuery({
    variables: {
      programId,
    },
  });

  const programNotes = programNotesData?.programNotes ?? [];
  const programNotesPlainText = useMemo(
    () =>
      programNotesData?.programNotes
        ? programNotesData?.programNotes.map((programNote) => ({
            ...programNote,
            note: markdownToTxt(programNote.note),
          }))
        : [],
    [programNotesData?.programNotes],
  );

  const [createProgramNote] = useCreateProgramNoteMutation();

  const debouncedSearch = useCallback(
    debounce(
      (e) => setSearchTerm(e.target.value.toLowerCase().trim()),
      DEFAULT_TRANSITION_DURATION,
    ),
    [],
  );

  const createNewProgramNote = async (title?: string, note?: string) => {
    try {
      const newNote = await createProgramNote({
        variables: {
          input: {
            programId,
            title: title ?? 'Untitled note',
            note: note ?? '',
          },
        },
      });

      await refetchProgramNotes();

      const newNoteId = newNote.data?.createProgramNote.id;
      if (newNoteId) {
        setSelectedProgramNote(undefined);
        navigate(`${newNoteId}?new=true`);
      }
    } catch (error) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to create new note."
          level="error"
        />
      ));
    }
  };

  const [updateProgramNote] = useUpdateProgramNoteMutation();

  const handlePinNote = async (programNote: ProgramNoteDataFragment) => {
    isSearching && closeSearch();

    try {
      await updateProgramNote({
        variables: {
          input: {
            programNoteId: programNote.id,
            isPinnedNote: !programNote.isPinnedNote,
          },
        },
      });

      refetchProgramNotes?.();
      refetchPinnedNotes();

      navigate(programNote.id);

      const pinnedNoteMessage = `Your note '${programNote.title}' is pinned and will now appear at the top.`;
      const unpinnedNoteMessage = `Your note '${programNote.title}' is unpinned.`;

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message={
            !programNote.isPinnedNote ? pinnedNoteMessage : unpinnedNoteMessage
          }
        />
      ));
    } catch (error) {
      console.error(error);

      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to update the note."
          level="error"
        />
      ));
    }
  };

  const closeSearch = () => {
    setSearchTermValue('');
    setSearchTerm('');
    setIsSearching(false);
    setProgramNotesSearch([]);
  };

  const highlightTermInText = (text: string, term: string) =>
    text ? text.replace(new RegExp(term, 'ig'), '<b>$&</b>') : '';
  const searchPredicate = (text: string, term: string) =>
    text.toLowerCase().includes(term.toLowerCase());
  const getSearchExcerpt = (text: string, term: string): string => {
    const excerptIdx = text.toLowerCase().indexOf(term.toLowerCase());
    const MAX_START_OFFSET = 32;
    const END_OFFSET = 500;
    let nonWordStartIdx = excerptIdx - 24;
    while (nonWordStartIdx >= 0) {
      if (NON_WORD_REGEX.test(text[nonWordStartIdx])) break;
      nonWordStartIdx--;
    }
    const startOffset = Math.min(
      MAX_START_OFFSET,
      excerptIdx - nonWordStartIdx - 1,
    );
    const start = Math.max(excerptIdx - startOffset, 0);
    const suffix = start <= 0 ? '' : '...';
    const end = Math.min(excerptIdx + END_OFFSET, text.length);
    return suffix + text.slice(start, end);
  };

  useEffect(() => {
    if (programNotesError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch notes."
          level="error"
        />
      ));
    }
  }, [programNotesError]);

  // Init the program notes list
  useEffect(() => {
    if (!programNotes || programNotesLoading) return;
    if (!hasLoadedProgramNotes) setHasLoadedProgramNotes(true);
  }, [programNotes]);

  // Select the program note when the program note id changes (notes/:programNoteId)
  useEffect(() => {
    // Nav to the first program note if there is no program note selected (notes/)
    if (!programNoteId && programNotes.length > 0) {
      navigate(programNotes[0].id);
      return;
    }

    if (!programNoteId || !hasLoadedProgramNotes) return;

    const newSelectedProgramNote = programNotes.find(
      (programNote) => programNote.id === programNoteId,
    );

    if (newSelectedProgramNote) {
      setSelectedProgramNote(newSelectedProgramNote);
    }
  }, [programNoteId, hasLoadedProgramNotes, programNotes]);

  // Search
  useEffect(() => {
    if (!searchTerm && programNotesSearch.length > 0) {
      // Clear search results
      setProgramNotesSearch([]);
      return;
    }
    if (!searchTerm || programNotes.length === 0) return;

    setProgramNotesSearch(
      programNotesPlainText.filter(
        (programNote) =>
          searchPredicate(programNote.title, searchTerm) ||
          searchPredicate(programNote.note, searchTerm),
      ),
    );
  }, [searchTerm]);

  useEffect(() => {
    if (hasPinnedNotes) {
      setIsPinnedNotesPanelOpen(true);
    }
  }, [hasPinnedNotes]);

  return (
    <>
      <Transition
        show={Boolean(programNotesData && !programNotesError)}
        {...defaultTransitionProps}
        className="h-full w-full"
      >
        <div className="h-full w-full">
          {!Boolean(programNotes.length) && !isSearching ? (
            <EmptyProfileTab
              IconComponent={IllustrationPencil}
              iconClassName={'text-orange-100'}
              titleText={`${patient?.firstName ?? ''}'s notes`}
              subTitleText={`Keep track of your sessions with ${patient?.firstName} by taking
                notes.`}
              ButtonComponent={
                <NoteActionsMenu
                  emptyButton
                  isOpen={isNoteActionsMenuOpen}
                  setIsOpen={setIsNoteActionsMenuOpen}
                  createNewProgramNote={createNewProgramNote}
                  authedProviderUserId={authedProviderUser.id}
                />
              }
            />
          ) : (
            <div className="flex h-full w-full flex-grow flex-row items-start pt-4">
              <div className="flex h-full w-1/6 min-w-[188px] flex-grow flex-col items-center justify-start">
                <div className="flex h-[64px] w-full flex-row items-center justify-between">
                  {isSearching ? (
                    <InputGroup
                      labelHidden
                      autoFocus
                      label="Search"
                      inputSize="small"
                      placeholder="Search"
                      className="w-full rounded-full pl-11"
                      containerClassName="mb-2"
                      IconLeft={Search}
                      iconLeftClassName="text-neutral-125 ml-1"
                      IconRight={CloseX}
                      iconRightOnClick={closeSearch}
                      iconRightClassName="text-neutral-125"
                      value={searchTermValue}
                      onChange={(e) => {
                        setSearchTermValue(e.target.value);
                        debouncedSearch(e);
                      }}
                    />
                  ) : (
                    <div className="flex h-full w-full flex-row items-center justify-between">
                      <NoteActionsMenu
                        isOpen={isNoteActionsMenuOpen}
                        setIsOpen={setIsNoteActionsMenuOpen}
                        createNewProgramNote={createNewProgramNote}
                        authedProviderUserId={authedProviderUser.id}
                      />
                      <IconButton
                        className="flex flex-row items-center justify-center hover:cursor-pointer hover:text-neutral-125/80 focus:outline-none"
                        onClick={() => {
                          setIsSearching(true);
                        }}
                        aria-label="Search notes"
                        IconComponent={SearchIcon}
                        iconClassName="h-5 w-5 text-neutral-125 rounded-full"
                      />
                    </div>
                  )}
                </div>
                <div className="mt-2 flex h-full w-full flex-col items-center justify-start gap-y-2 overflow-y-auto overscroll-y-contain border-t border-neutral-75 pt-2">
                  {programNotes.map((programNote, index) => {
                    return (
                      <NotePill
                        key={programNote.id}
                        isLastNote={programNotes.length - 1 === index}
                        programNoteId={programNoteId}
                        programNote={programNote}
                        handleNotePillClick={() => {
                          if (isSearching) closeSearch();
                          navigate(programNote.id);
                        }}
                        handlePinNoteClick={(e) => {
                          handlePinNote(programNote);
                          e.stopPropagation();
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="h-full w-full">
                {!isSearching ? (
                  <Outlet
                    context={{
                      patient,
                      refetchProgramNotes,
                      refetchPinnedNotes,
                      selectedProgramNote,
                      isSearching,
                      isUnsavedChangesModalOpen,
                      setIsUnsavedChangesModalOpen,
                      setNavTransition,
                    }}
                  />
                ) : (
                  <div className="ml-7 mt-4 flex h-full flex-col">
                    <div className="mb-4 w-full text-body font-medium text-neutral-125">
                      Search results
                    </div>
                    {Boolean(programNotesSearch.length) ? (
                      <>
                        {programNotesSearch.map((programNote, index) => (
                          <div
                            className="mb-2 w-full cursor-pointer rounded-md border border-neutral-75 bg-neutral-25 py-3 px-5 hover:bg-neutral-50"
                            key={index}
                            onClick={() => {
                              closeSearch();
                              navigate(programNote.id);
                            }}
                          >
                            <div
                              className="text-caption text-neutral-110"
                              dangerouslySetInnerHTML={{
                                __html: highlightTermInText(
                                  programNote.title,
                                  searchTerm,
                                ),
                              }}
                            />
                            <div
                              className="text-small-caption text-neutral-110 line-clamp-1"
                              dangerouslySetInnerHTML={{
                                __html: highlightTermInText(
                                  getSearchExcerpt(
                                    programNote.note,
                                    searchTerm,
                                  ),
                                  searchTerm,
                                ),
                              }}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      searchTerm && (
                        <span className="text-caption font-medium text-neutral-125/80">
                          No notes match this search.
                        </span>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Transition>

      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => {
          navTransition?.retry();
          setIsUnsavedChangesModalOpen(false);
        }}
      />

      {programNotesLoading && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default Notes;

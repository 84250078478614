import classNames from 'classnames';
import { FC } from 'react';

import CloseX from '../svgs/CloseX';

import Button from './Button';

import { XIcon } from '@heroicons/react/outline';
import InputGroup from './InputGroup';
import Search from '../svgs/Search';
import SelectMenu from './SelectMenu';
import { contentTypeFilterValues } from './Library/helpers';
import { ContentType } from '../lib/followUp';

const ContentTypeFilterOption: FC<{ value: ContentType }> = ({ value }) => {
  const label = [
    ContentType.PDF,
    ContentType.PdfForm,
    ContentType.Esign,
  ].includes(value)
    ? 'File'
    : value;
  return <>{label}</>;
};

const SearchAndFilters: FC<{
  searchTermValue: string;
  clearSearchTerm: () => void;
  clearFilterTerm: () => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  contentTypeFilter: ContentType | null;
  setContentTypeFilter: React.Dispatch<
    React.SetStateAction<ContentType | null>
  >;
  contentTypeFilterLock?: ContentType;
  patientMode?: boolean;
}> = ({
  searchTermValue,
  clearSearchTerm,
  clearFilterTerm,
  onChangeSearch,
  contentTypeFilter,
  setContentTypeFilter,
  contentTypeFilterLock,
  patientMode = false,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-center',
        patientMode
          ? 'h-24 flex-col items-start justify-between md:h-[48px] md:flex-row md:justify-start'
          : 'h-12 flex-row items-center',
      )}
    >
      <InputGroup
        labelHidden
        label="Search"
        placeholder="Search..."
        className={classNames(
          'w-80 rounded-full pl-12 disabled:cursor-not-allowed',
        )}
        inputSize="extra-small"
        containerClassName="mr-4"
        iconLeftClassName="text-neutral-125 ml-2"
        IconLeft={Search}
        IconRight={searchTermValue.length > 0 ? CloseX : null}
        iconRightOnClick={clearSearchTerm}
        iconRightClassName="text-neutral-125 mr-1"
        value={searchTermValue}
        onChange={onChangeSearch}
      />
      {!contentTypeFilterLock && (
        <div className="flex flex-row items-center justify-start">
          <div
            className={classNames(
              'relative z-10 transition',
              !contentTypeFilter && 'opacity-0',
              contentTypeFilter && 'opacity-100',
            )}
          >
            <Button
              className="h-[24px] w-[24px] rounded-full"
              iconClassName="min-h-[14px] min-w-[14px] ml-0"
              size="extra-small"
              aria-label="Clear filters"
              IconComponent={XIcon}
              onClick={clearFilterTerm}
            />
          </div>
          <div
            className={classNames(
              'relative z-20 flex flex-row items-center justify-start transition',
              !contentTypeFilter && '-translate-x-6',
              contentTypeFilter && 'translate-x-[10px]',
            )}
          >
            <SelectMenu
              fieldValue={contentTypeFilter}
              placeholder="Filter by type..."
              label=""
              hideLabel
              fieldOptions={contentTypeFilterValues(patientMode)}
              onChange={(value) => setContentTypeFilter(value as ContentType)}
              buttonClassName="!py-2"
              containerClassName="w-[160px]"
              SelectOptionComponent={ContentTypeFilterOption}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchAndFilters;

import {
  AssessmentSignatureUser,
  MeProviderQuery,
  PatientDataFragment,
  SignatureUser,
} from '../../../../../generated/graphql';

export const createProgramAssessmentSignatureUsers = (
  assessmentSignatureUsers?: AssessmentSignatureUser[],
  sendingProviderUser?: MeProviderQuery['meProvider'] | null,
  patient?: PatientDataFragment,
) => {
  if (!assessmentSignatureUsers || !sendingProviderUser || !patient) {
    return [];
  }

  const assignedProviderUsers = patient.assignedProviderUsers.filter(
    (assignedProviderUser) =>
      assignedProviderUser.id !== sendingProviderUser.id,
  );

  const programAssessmentSignatureUsers = assessmentSignatureUsers.map(
    (assessmentSignatureUser) => {
      return {
        assessmentSignatureUserId: assessmentSignatureUser.id,
        ...(assessmentSignatureUser.signatureUserType ===
          SignatureUser.PatientUser && {
          name: patient.name,
        }),
        ...(assessmentSignatureUser.signatureUserType ===
          SignatureUser.SendingProviderUser && {
          providerUserId: sendingProviderUser.id,
          name: sendingProviderUser.name,
        }),
        ...(assessmentSignatureUser.signatureUserType ===
          SignatureUser.AssignedProviderUser && {
          providerUserId: assignedProviderUsers[0].id,
          name: assignedProviderUsers[0].name,
        }),
      };
    },
  );

  return programAssessmentSignatureUsers;
};

export const checkNotEnoughAssignedProviderUsers = (
  assessmentSignatureUsers: AssessmentSignatureUser[],
  patient: PatientDataFragment | undefined,
  sendingProviderUser: any,
): boolean => {
  const assignedProviderUsersCount = patient?.assignedProviderUsers.length || 0;
  const assignedProviderUserTypesCount =
    assessmentSignatureUsers?.filter(
      (user) => user.signatureUserType === SignatureUser.AssignedProviderUser,
    ).length || 0;

  const isSendingProviderUserIncluded = assessmentSignatureUsers?.some(
    (user) => user.signatureUserType === SignatureUser.SendingProviderUser,
  );

  const isSendingProviderUserAssigned = patient?.assignedProviderUsers.some(
    (assignedUser) => assignedUser.id === sendingProviderUser?.id,
  );

  const effectiveAssignedProviderUsersCount = isSendingProviderUserIncluded
    ? assignedProviderUsersCount - (isSendingProviderUserAssigned ? 1 : 0)
    : assignedProviderUsersCount;

  return effectiveAssignedProviderUsersCount < assignedProviderUserTypesCount;
};

export const initializeSelectedAssignedProviderUsers = (
  assessmentSignatureUsers: AssessmentSignatureUser[] | undefined,
  sendingProviderUser: MeProviderQuery['meProvider'] | null,
  patient: PatientDataFragment | undefined,
  setSelectedAssignedProviderUsers: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >,
) => {
  if (!assessmentSignatureUsers || !patient) {
    setSelectedAssignedProviderUsers({});
    return;
  }

  let availableAssignedProviderUserIds = patient.assignedProviderUsers.map(
    (user) => user.id,
  );
  const selectedAssignedProviderUsers: { [key: string]: string } = {};

  for (const assessmentSignatureUser of assessmentSignatureUsers) {
    if (
      assessmentSignatureUser.signatureUserType ===
        SignatureUser.SendingProviderUser &&
      sendingProviderUser
    ) {
      selectedAssignedProviderUsers[assessmentSignatureUser.id] =
        sendingProviderUser.id;
      availableAssignedProviderUserIds =
        availableAssignedProviderUserIds.filter(
          (id) => id !== sendingProviderUser.id,
        );
    } else if (
      assessmentSignatureUser.signatureUserType ===
        SignatureUser.AssignedProviderUser &&
      availableAssignedProviderUserIds.length > 0
    ) {
      const [nextAssignedProviderUserId, ...remainingAssignedProviderUserIds] =
        availableAssignedProviderUserIds;
      selectedAssignedProviderUsers[assessmentSignatureUser.id] =
        nextAssignedProviderUserId;
      availableAssignedProviderUserIds = remainingAssignedProviderUserIds;
    }
  }

  setSelectedAssignedProviderUsers(selectedAssignedProviderUsers);
};

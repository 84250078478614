import { SignatureUser } from '../../generated/graphql';

export enum SignatureUserTypeLabel {
  PatientUser = 'Client',
  SendingProviderUser = 'Sender',
  AssignedProviderUser = 'Team member',
}

type SignatureUserColorScheme = {
  borderColor: string;
  backgroundColor: string;
  disabledBackgroundColor: string;
};

export const getColorForSignatureUserType = (
  userType: SignatureUser | 'disabled',
): SignatureUserColorScheme => {
  switch (userType) {
    case SignatureUser.PatientUser:
      return {
        borderColor: 'border-blue-25',
        backgroundColor: 'bg-blue-25',
        disabledBackgroundColor: 'bg-blue-25/50',
      }; // Light blue for Client
    case SignatureUser.SendingProviderUser:
      return {
        borderColor: 'border-green-25',
        backgroundColor: 'bg-green-25',
        disabledBackgroundColor: 'bg-green-25/50',
      }; // Light green for Sending practitioner
    case SignatureUser.AssignedProviderUser:
      return {
        borderColor: 'border-purple-25',
        backgroundColor: 'bg-purple-25',
        disabledBackgroundColor: 'bg-purple-25/50',
      }; // Light purple for Team member
    case 'disabled':
      return {
        borderColor: 'border-neutral-25',
        backgroundColor: 'bg-neutral-25',
        disabledBackgroundColor: 'bg-neutral-25/50',
      }; // Light purple for Team member
    default:
      return {
        borderColor: 'border-white',
        backgroundColor: 'bg-white',
        disabledBackgroundColor: 'bg-white/50',
      }; // Default white
  }
};

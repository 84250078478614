import { FC } from 'react';
import classNames from 'classnames';
import { marked } from 'marked';

import {
  SignatureUser,
  AssessmentAnswer,
  AssessmentQuestionType,
  FreeTextAssessmentQuestion,
  RichTextAssessmentQuestion,
  SignatureAssessmentQuestion,
  MultiSelectAssessmentQuestion,
  MultiSignatureAssessmentQuestion,
  MultipleChoiceAssessmentQuestion,
  ProgramAssessmentSignatureUserDataFragment,
  InitialsAssessmentQuestion,
} from '../../../generated/graphql';

import {
  isFreeTextAssessmentAnswer,
  isRichTextAssessmentAnswer,
  isSignatureAssessmentAnswer,
  isMultiSelectAssessmentAnswer,
  isMultipleChoiceAssessmentAnswer,
  isInitialsAssessmentAnswer,
} from '../../lib/assessments';

import markdownToTxt from '../../lib/markdown-to-txt';
import { ProgramAssessmentQuestion } from '../../lib/program-assessment';

import SignatureFlagName from '../../svgs/SignatureFlagName';
import Checkbox from '../../components/Checkbox';
import InputGroup from '../../components/InputGroup';
import TextAreaGroup from '../../components/TextAreaGroup';

import CompletedSignature from '../../components/CompletedSignature';

const QuestionField: FC<{ question: string }> = ({ question }) => {
  return (
    <div className="mb-4 text-body">
      <div
        className="editor text-green-150"
        dangerouslySetInnerHTML={{ __html: marked(question) }}
      />
    </div>
  );
};

const Question: FC<{
  question: ProgramAssessmentQuestion;
  answer?: AssessmentAnswer;
  programAssessmentSignatureUsers: ProgramAssessmentSignatureUserDataFragment[];
  currentSigner: ProgramAssessmentSignatureUserDataFragment;
  signatureName: string;
  signatureRole: string;
  signatureTermsAgreed: boolean;
  nowFormattedTimestamp: string;
}> = ({
  question,
  answer,
  programAssessmentSignatureUsers,
  currentSigner,
  signatureName,
  signatureRole,
  signatureTermsAgreed,
  nowFormattedTimestamp,
}) => {
  switch (question.questionType) {
    case AssessmentQuestionType.MultiSignature:
      question = question as MultiSignatureAssessmentQuestion;

      return (
        <div>
          <QuestionField question={question.question} />
          {question.assessmentSignatureOrderIndices.map(
            (orderIndex, index, array) => {
              const isLastSignature = index === array.length - 1;
              const programAssessmentSignatureUser =
                programAssessmentSignatureUsers.find(
                  (user) =>
                    user.assessmentSignatureUser.orderIndex === orderIndex,
                );

              const isCurrentSigner =
                programAssessmentSignatureUser?.assessmentSignatureUser
                  ?.orderIndex ===
                currentSigner?.assessmentSignatureUser?.orderIndex;

              const isNotPatientSigner =
                programAssessmentSignatureUser.assessmentSignatureUser
                  ?.signatureUserType !== SignatureUser.PatientUser;

              return (
                <div key={index}>
                  {(programAssessmentSignatureUser?.signedAt ||
                    (isCurrentSigner &&
                      signatureTermsAgreed &&
                      signatureRole &&
                      signatureName)) && (
                    <CompletedSignature
                      isCurrentSigner={isCurrentSigner}
                      signatureName={signatureName}
                      signatureRole={
                        isNotPatientSigner ? signatureRole : 'Client'
                      }
                      nowFormattedTimestamp={nowFormattedTimestamp}
                      programAssessmentSignatureUser={
                        programAssessmentSignatureUser
                      }
                      className={classNames(
                        'mt-2',
                        !isLastSignature && !isCurrentSigner ? 'mb-6' : 'mb-4',
                      )}
                    />
                  )}
                </div>
              );
            },
          )}
        </div>
      );
    case AssessmentQuestionType.FreeText:
      question = question as FreeTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <TextAreaGroup
            key={markdownToTxt(question.question)}
            label={question.question}
            value={
              answer && isFreeTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            labelHidden
            readOnly
          />
        </div>
      );
    case AssessmentQuestionType.RichText:
      question = question as RichTextAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          <TextAreaGroup
            key={markdownToTxt(question.question)}
            label={question.question}
            value={
              answer && isRichTextAssessmentAnswer(answer) ? answer.answer : ''
            }
            labelHidden
            readOnly
          />
        </div>
      );

    case AssessmentQuestionType.Statement:
      question = question as FreeTextAssessmentQuestion;
      return <QuestionField question={question.question} />;

    case AssessmentQuestionType.Initials:
      question = question as InitialsAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />

          {answer && isInitialsAssessmentAnswer(answer) && (
            <CompletedSignature
              signatureName={answer.signatureName}
              signatureRole={'Client'}
              signedAt={answer.signedAt}
              className="my-2"
            />
          )}
          {!answer && (
            <>
              <div className="flex flex-col items-start justify-start">
                <div className="mb-2 text-caption font-medium">
                  Click the box to add your initials
                </div>
                <InputGroup
                  IconLeft={SignatureFlagName}
                  iconLeftClassName="h-5 w-5 -ml-1"
                  containerClassName="mt-2 mb-1 w-[120px]"
                  className="cursor-default rounded-lg"
                  inputSize="extra-small"
                  disabled
                />
              </div>
            </>
          )}
        </div>
      );

    case AssessmentQuestionType.Signature:
      question = question as SignatureAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />

          {answer && isSignatureAssessmentAnswer(answer) && (
            <CompletedSignature
              signatureName={answer.signatureName}
              signatureRole={'Client'}
              signedAt={answer.signedAt}
              className="my-2"
            />
          )}
          {!answer && (
            <>
              <div className="mb-3 text-caption font-medium">
                Type your name to sign electronically
              </div>
              <InputGroup
                placeholder="Client name"
                IconLeft={SignatureFlagName}
                containerClassName="mb-2 w-[398px]"
                className="rounded-lg pl-12"
                disabled
              />
            </>
          )}
        </div>
      );

    case AssessmentQuestionType.MultiSelect:
      question = question as MultiSelectAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          {question && (
            <div>
              {question.answerOptions.map((questionAnswerOption, index) => (
                <Checkbox
                  id=""
                  key={`${index}_${questionAnswerOption.answerOption}`}
                  readOnly
                  className="mb-2"
                  value={questionAnswerOption.answerOption}
                  checked={
                    answer &&
                    isMultiSelectAssessmentAnswer(answer) &&
                    answer.answers.includes(questionAnswerOption.answerOption)
                  }
                  labelContent={
                    <div className="ml-2 text-caption">
                      {questionAnswerOption.answerOption}
                    </div>
                  }
                />
              ))}
            </div>
          )}
        </div>
      );

    case AssessmentQuestionType.MultipleChoice:
      question = question as MultipleChoiceAssessmentQuestion;
      return (
        <div>
          <QuestionField question={question.question} />
          {question && (
            <div>
              {question.answerOptions.map((questionAnswerOption, index) => (
                <Checkbox
                  id=""
                  key={`${index}_${questionAnswerOption.answerOption}`}
                  readOnly
                  className="mb-2"
                  type="radio"
                  value={questionAnswerOption.answerOption}
                  checked={
                    answer &&
                    isMultipleChoiceAssessmentAnswer(answer) &&
                    answer.answer.includes(questionAnswerOption.answerOption)
                  }
                  labelContent={
                    <div className="ml-2 text-caption">
                      {questionAnswerOption.answerOption}
                    </div>
                  }
                />
              ))}
            </div>
          )}
        </div>
      );
  }

  return <></>;
};

export default Question;

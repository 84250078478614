import classNames from 'classnames';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef<
  HTMLInputElement,
  {
    id: string;
    className?: string;
    labelContent?: React.ReactNode;
    checkboxClassName?: string;
    readOnly?: boolean;
    type?: 'checkbox' | 'radio';
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    {
      id,
      value,
      className,
      labelContent,
      disabled,
      checkboxClassName,
      readOnly,
      type = 'checkbox',
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={classNames('flex', className)}>
        <input
          id={id}
          type={type}
          ref={ref}
          className={classNames(
            'mr-2 h-5 w-5 border-neutral-100 focus:ring-green-125',
            type === 'radio' ? 'rounded-full' : 'rounded-md',
            checkboxClassName,
            readOnly && 'cursor-default',
            disabled
              ? 'cursor-not-allowed text-neutral-100'
              : 'cursor-pointer text-green-125',
          )}
          disabled={disabled || readOnly}
          {...rest}
        />
        {labelContent && (
          <label
            className={classNames(
              'text-caption',
              readOnly && 'cursor-default',
              disabled
                ? 'cursor-not-allowed text-neutral-100'
                : 'cursor-pointer text-neutral-125',
            )}
            htmlFor={id}
          >
            {labelContent}
          </label>
        )}
      </div>
    );
  },
);

export default Checkbox;

import { FC } from 'react';
import classNames from 'classnames';

import {
  ProgramActivityDataFragment,
  UserType,
} from '../../../generated/graphql';

import { getTimeStamp } from '../../lib/time';
import { activityTypeDisplay } from '../../lib/activity';
import { programActivityTitle } from '../../lib/programActivity';

import { ProgramActivityStatus } from '../ProgramActivityStatus';
import { ProgramActivityThumbnail } from '../ProgramActivityThumbnail';
import { ProgramActivities } from '.';

const ActivitySummary: FC<{
  programActivity: ProgramActivityDataFragment;
}> = ({ programActivity }) => {
  const title = programActivityTitle(programActivity);
  const subtitle = programActivity.providerNote;

  return (
    <div className="flex items-center">
      <ProgramActivityThumbnail
        programActivity={programActivity}
        className="mr-4 h-12 w-12"
      />
      <div className="flex-col text-green-150">
        <div className="text-caption font-medium line-clamp-2">{title}</div>
        {subtitle && (
          <div className="text-small-caption text-neutral-125 line-clamp-1">
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

const ActivityRow: FC<{
  programActivity: ProgramActivities[number];
  onClickProgramActivity: (
    programActivity: ProgramActivityDataFragment,
  ) => void;
  patientResourcesMode?: boolean;
}> = ({
  programActivity,
  onClickProgramActivity,
  patientResourcesMode = false,
}) => {
  return (
    <tr
      className={classNames(
        'h-[72px] cursor-pointer text-ellipsis text-caption text-green-150 hover:bg-neutral-25',
      )}
      onClick={() => onClickProgramActivity(programActivity)}
    >
      <td className="max-w-xs">
        <ActivitySummary programActivity={programActivity} />
      </td>

      <td className="min-w-[80px]">{activityTypeDisplay(programActivity)}</td>

      <td className="min-w-[140px]">
        <ProgramActivityStatus
          programActivity={programActivity}
          className="text-small-caption"
          userType={
            patientResourcesMode ? UserType.Patient : UserType.ProviderUser
          }
        />
      </td>

      <td className="min-w-[130px]">
        {getTimeStamp(programActivity.sentAt, false)}
      </td>

      <td className="min-w-[130px]">
        {programActivity.completedAt
          ? getTimeStamp(programActivity.completedAt, false)
          : '-'}
      </td>
    </tr>
  );
};

export default ActivityRow;

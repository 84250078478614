import { FC, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Transition } from '@headlessui/react';

import ScoreSection from './Assessments/ScoreSection';
import Slideover from './Slideover';
import SlideoverSection from './SlideoverSection';
import ToastAlert from './ToastAlert';
import { getTimeStamp } from '../lib/time';
import { defaultTransitionProps } from '../lib/animation';
import {
  AssessmentSchemaType,
  useProgramAssessmentQuery,
} from '../../generated/graphql';
import ResponsesSection from './Assessments/ResponsesSection';

const AssessmentCompletedSlideover: FC<{
  onClose: () => void;
  isOpen: boolean;
  programAssessmentId: string;
  patientFirstName?: string;
}> = ({ onClose, isOpen, patientFirstName, programAssessmentId }) => {
  const {
    data: programAssessmentData,
    error: programAssessmentError,
    loading: isLoadingprogramAssessment,
  } = useProgramAssessmentQuery({
    variables: {
      programAssessmentId,
    },
    skip: !programAssessmentId,
  });

  const programAssessment = programAssessmentData?.programAssessment;

  useEffect(() => {
    if (programAssessmentError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch completed assessment."
          level="error"
        />
      ));
    }
  }, [programAssessmentError]);

  const assessmentTimestampTitle =
    getTimeStamp(programAssessment?.completedAt, false) ?? '';

  const isCustomAssessment =
    programAssessment?.assessment.schemaType === AssessmentSchemaType.Custom;

  return (
    <Slideover
      isOpen={isOpen}
      fetching={isLoadingprogramAssessment}
      title={programAssessment?.assessment.shortName ?? ''}
      subtitle={assessmentTimestampTitle}
      onClose={onClose}
      showContent={
        !isLoadingprogramAssessment &&
        !programAssessmentError &&
        Boolean(programAssessmentData)
      }
    >
      <Transition show={true} {...defaultTransitionProps}>
        {programAssessment && (
          <>
            {!isCustomAssessment && (
              <ScoreSection
                assessmentType={programAssessment?.assessment.type}
                title="Score"
                completedAssessment={programAssessment ?? undefined}
              />
            )}
            {Boolean(programAssessment?.additionalThoughts) && (
              <SlideoverSection
                title={`Notes from ${patientFirstName}`}
                textContent={
                  `"${programAssessment?.additionalThoughts}"` ?? undefined
                }
              />
            )}
            <ResponsesSection
              title="Responses"
              questionFrame={
                programAssessment?.assessment?.questionFrame ?? undefined
              }
              programAssessment={programAssessment}
            />
            <SlideoverSection
              title="About"
              textContent={programAssessment?.assessment?.description}
            />
          </>
        )}
      </Transition>
    </Slideover>
  );
};

export default AssessmentCompletedSlideover;

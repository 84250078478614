import {
  Assessment,
  AssessmentAnswer,
  AssessmentQuestion,
  ActivityDataFragment,
  AssessmentSchemaType,
  AssessmentQuestionType,
  FreeTextAssessmentAnswer,
  FreeTextAssessmentQuestion,
  MultiSelectAssessmentAnswer,
  MultiSelectAssessmentQuestion,
  MultiSignatureAssessmentAnswer,
  MultiSignatureAssessmentQuestion,
  MultipleChoiceAssessmentAnswer,
  MultipleChoiceAssessmentQuestion,
  MultipleChoiceScoredAssessmentAnswer,
  MultipleChoiceScoredAssessmentQuestion,
  RichTextAssessmentAnswer,
  RichTextAssessmentQuestion,
  SignatureAssessmentAnswer,
  SignatureAssessmentQuestion,
  StatementAssessmentQuestion,
  AssessmentOnProgramAssessmentDataFragment,
  InitialsAssessmentQuestion,
  InitialsAssessmentAnswer,
} from '../../generated/graphql';
import { ProviderActivityType } from '../types/activity';

export const isAssessmentAForm = (
  assessment: Assessment | AssessmentOnProgramAssessmentDataFragment,
) => Boolean(assessment?.schemaType === AssessmentSchemaType.Custom);

export const isStandardAssessment = (
  providerActivity?: ActivityDataFragment,
): boolean => {
  if (!providerActivity) {
    return false;
  }

  return (
    providerActivity.__typename === ProviderActivityType.Assessment &&
    !isAssessmentAForm(providerActivity.assessment)
  );
};

export enum AssessmentQuestionTypename {
  MultipleChoiceScoredAssessmentQuestion = 'MultipleChoiceScoredAssessmentQuestion',
  MultipleChoiceAssessmentQuestion = 'MultipleChoiceAssessmentQuestion',
  FreeTextAssessmentQuestion = 'FreeTextAssessmentQuestion',
  RichTextAssessmentQuestion = 'RichTextAssessmentQuestion',
  MultipleChoiceScoredAssessmentQuestionAnswerOption = 'MultipleChoiceScoredAssessmentQuestionAnswerOption',
}

// When using these, we assume that *all* the subfields are requested, otherwise typing will be off

export function isMultipleChoiceScoredAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is MultipleChoiceScoredAssessmentQuestion {
  return (
    assessmentQuestion.questionType ===
    AssessmentQuestionType.MultipleChoiceScored
  );
}

export function isMultipleChoiceAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is MultipleChoiceAssessmentQuestion {
  return (
    assessmentQuestion.questionType === AssessmentQuestionType.MultipleChoice
  );
}

export function isFreeTextAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is FreeTextAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.FreeText;
}

export function isRichTextAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is RichTextAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.RichText;
}

export function isStatementAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is StatementAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.Statement;
}

export function isMultiSelectAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is MultiSelectAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.MultiSelect;
}

export function isInitialsAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is InitialsAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.Initials;
}

export function isSignatureAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is SignatureAssessmentQuestion {
  return assessmentQuestion.questionType === AssessmentQuestionType.Signature;
}

export function isMultiSignatureAssessmentQuestion(
  assessmentQuestion: AssessmentQuestion,
): assessmentQuestion is MultiSignatureAssessmentQuestion {
  return (
    assessmentQuestion.questionType === AssessmentQuestionType.MultiSignature
  );
}

export const isSignatureTypeAssessmentQuestion = (
  question: AssessmentQuestion,
) => {
  const isInitialsQuestion = isInitialsAssessmentQuestion(question);
  const isSignatureQuestion = isSignatureAssessmentQuestion(question);
  const isMultiSignatureQuestion = isMultiSignatureAssessmentQuestion(question);

  return isInitialsQuestion || isSignatureQuestion || isMultiSignatureQuestion;
};

// AssessmentAnswer type check functions

export function isFreeTextAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is FreeTextAssessmentAnswer {
  return assessmentAnswer.questionType === AssessmentQuestionType.FreeText;
}

export function isMultipleChoiceScoredAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is MultipleChoiceScoredAssessmentAnswer {
  return (
    assessmentAnswer.questionType ===
    AssessmentQuestionType.MultipleChoiceScored
  );
}

export function isMultipleChoiceAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is MultipleChoiceAssessmentAnswer {
  return (
    assessmentAnswer.questionType === AssessmentQuestionType.MultipleChoice
  );
}

export function isMultiSelectAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is MultiSelectAssessmentAnswer {
  return assessmentAnswer.questionType === AssessmentQuestionType.MultiSelect;
}

export function isRichTextAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is RichTextAssessmentAnswer {
  return assessmentAnswer.questionType === AssessmentQuestionType.RichText;
}

export function isInitialsAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is InitialsAssessmentAnswer {
  return assessmentAnswer.questionType === AssessmentQuestionType.Initials;
}

export function isSignatureAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is SignatureAssessmentAnswer {
  return assessmentAnswer.questionType === AssessmentQuestionType.Signature;
}

export function isMultiSignatureAssessmentAnswer(
  assessmentAnswer: AssessmentAnswer,
): assessmentAnswer is MultiSignatureAssessmentAnswer {
  return (
    assessmentAnswer.questionType === AssessmentQuestionType.MultiSignature
  );
}

export enum AssessmentCompletionDataTypename {
  MultipleChoiceSumScoredAssessmentCompletionData = 'MultipleChoiceSumScoredAssessmentCompletionData',
  HADSAssessmentCompletionData = 'HADSAssessmentCompletionData',
  CustomAssessmentCompletionData = 'CustomAssessmentCompletionData',
}

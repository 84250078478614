import {
  UserType,
  SignatureUser,
  ProgramActivityDataFragment,
} from '../../../generated/graphql';

export const getSignatureWarningMessage = (
  programActivity: ProgramActivityDataFragment,
  userType: UserType,
  authedProviderUserId?: string,
): string => {
  const baseMessage = 'This form still needs other signatures.';

  const userHasSigned =
    programActivity.programAssessment?.programAssessmentSignatureUsers.some(
      (user) => {
        if (userType === UserType.ProviderUser) {
          return (
            user.providerUser?.id === authedProviderUserId &&
            user.signedAt !== null
          );
        }
        if (userType === UserType.Patient) {
          return (
            user.assessmentSignatureUser.signatureUserType ===
              SignatureUser.PatientUser && user.signedAt !== null
          );
        }
        return false;
      },
    );

  return userHasSigned
    ? baseMessage
    : `${baseMessage} You'll receive a notification when it's your turn to sign.`;
};

import { FC } from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { PatientNameDataFragment } from '../../../generated/graphql';
import PatientPill from './PatientPill';
import { pluralize } from '../../lib/copy';

const SendToTagConfirmationModal: FC<{
  isModalOpen: boolean;
  setClosed: () => void;
  onConfirm: () => void;
  recipientPatientNames: PatientNameDataFragment[];
}> = ({ isModalOpen, setClosed, onConfirm, recipientPatientNames }) => {
  return (
    <Modal
      name="SendToTagConfirmationModal"
      isOpen={isModalOpen}
      setClosed={setClosed}
      width="small"
    >
      <div className="flex flex-col items-center justify-center px-12 py-10">
        <div className="mb-2 flex text-center font-serif text-small text-green-150">
          Are you sure you want to send this mail to{' '}
          {pluralize(recipientPatientNames.length, 'client', 'clients', true)},
          including those in tags?
        </div>
        <div className="mt-4 flex max-h-[250px] flex-wrap justify-center gap-x-2 gap-y-2 overflow-y-scroll">
          {recipientPatientNames.map((recipientPatientName) => (
            <PatientPill
              key={recipientPatientName.id}
              patient={recipientPatientName}
            />
          ))}
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={setClosed}
          />
          <Button title="Send" theme="primary" onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  );
};

export default SendToTagConfirmationModal;

import { FC, useState } from 'react';

import { BasicProviderUserMemberDataFragment } from '../../../../../../generated/graphql';
import Avatar from '../../../../../components/Avatar';
import Button from '../../../../../components/Button';

interface ProviderUserRowProps {
  providerUser: BasicProviderUserMemberDataFragment;
  handleRemoveProviderUserFromPatientFromPatient: (
    providerUserId: string,
  ) => Promise<void>;
}

const ProviderUserRow: FC<ProviderUserRowProps> = ({
  providerUser,
  handleRemoveProviderUserFromPatientFromPatient,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const handleOnClickRemoveProviderUser = async () => {
    try {
      setIsRemoving(true);
      await handleRemoveProviderUserFromPatientFromPatient(providerUser.id);
    } catch (err) {
      console.log(err);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
    <div className="flex h-[48px] w-full flex-row items-center justify-between text-caption text-green-150">
      <div className="flex flex-row items-center gap-x-4">
        <Avatar
          imageUrl={providerUser.profileImageMedia?.url}
          name={providerUser.name}
          size="medium"
        />
        <div className="flex flex-col items-start justify-start font-serif text-extra-small text-green-150">
          <div>{providerUser.name}</div>
        </div>
      </div>

      <Button
        size="smaller"
        title={!isRemoving ? 'Remove' : 'Removing...'}
        noFocus
        disabled={isRemoving}
        theme="primary"
        onClick={handleOnClickRemoveProviderUser}
      />
    </div>
  );
};

export default ProviderUserRow;

import { FC, useRef, useEffect } from 'react';
import classNames from 'classnames';
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { SparklesIcon, TrashIcon } from '@heroicons/react/outline';

import { ActivityType, ActivityUseType } from '../../../generated/graphql';

import {
  ContentType,
  ActivityOrResourceOptions,
  FollowUpForm,
  SavedActivityInputPreview,
} from '../../lib/followUp';

import { resizeTextArea } from '../../lib/form';

import TextAreaGroup from '../../components/TextAreaGroup';
import ContentPreview from '../../components/ContentPreview';
import IconButton from '../../components/IconButton';
import {
  activityCompletionTypeFromActivityType,
  activityFileCompletionTypeDisplay,
} from '../../lib/activity';

export type ActivityCreationCardProps = {
  activity: SavedActivityInputPreview;
  activityIndex: number;
  register: UseFormRegister<FollowUpForm>;
  setValue: UseFormSetValue<FollowUpForm>;
  watch: UseFormWatch<FollowUpForm>;
  errors: FieldErrorsImpl<DeepRequired<FollowUpForm>>;
  activitiesLength: number;
  onClickCloseX?: () => void;
  onClickModifyFileSettings?: (activityPdfId: string) => void;
  // ActivityPreviewCardProps
  contentType: ContentType;
  activityUseType?: ActivityUseType;
  title: string;
  activityOrResourceOption?: ActivityOrResourceOptions;
  showProviderNote?: boolean;
  description?: string;
  providerNote?: string;
  previewLinkUrl?: string;
  previewThumbnailImageUrl?: string;
  className?: string;
  handleUpdateActivityUseType: (
    activityId: string,
    activityType: ActivityType,
    activityUseType: ActivityUseType,
    activityIndex: number,
    activity: SavedActivityInputPreview,
  ) => Promise<void>;
  requiresSendingProviderSignatureFirst: boolean;
};

export type ActivityPreviewForm = {
  providerNote: string;
};

const ActivityCreationCard: FC<ActivityCreationCardProps> = ({
  activity,
  activityIndex,
  register,
  setValue,
  watch,
  errors,
  activitiesLength,
  onClickCloseX,
  onClickModifyFileSettings,
  // ActivityPreviewCardProps
  activityUseType,
  contentType,
  title,
  description,
  previewLinkUrl,
  previewThumbnailImageUrl,
  className,
  handleUpdateActivityUseType,
  requiresSendingProviderSignatureFirst,
}) => {
  const activityTypeRegisterValue: `activities.${number}.sendFollowUpActivityInputRaw.activityType` = `activities.${activityIndex}.sendFollowUpActivityInputRaw.activityType`;
  const providerNoteRegisterValue: `activities.${number}.sendFollowUpActivityInputRaw.providerNote` = `activities.${activityIndex}.sendFollowUpActivityInputRaw.providerNote`;
  const activityUseTypeRegisterValue: `activities.${number}.activityPreviewCardProps.activityUseType` = `activities.${activityIndex}.activityPreviewCardProps.activityUseType`;

  const watchActivityType: ActivityType = watch(activityTypeRegisterValue);
  const watchActivityUseType: ActivityUseType = watch(
    activityUseTypeRegisterValue,
  );

  const watchProviderNote = watch(providerNoteRegisterValue);
  const providerNoteFieldRef = useRef<HTMLTextAreaElement | null>(null);

  // resizes ProviderNoteTextAreas on initial load
  // tracking activitiesLength ensures newlines render correctly on activity removal
  useEffect(() => {
    resizeTextArea(providerNoteFieldRef);
  }, [providerNoteFieldRef, activitiesLength]);

  const { ref: providerNoteRef, ...providerNoteRegister } = register(
    providerNoteRegisterValue,
    {
      ...(watchActivityType === ActivityType.Action && {
        required: {
          value: true,
          message: 'Guidance text is required for an action',
        },
      }),
      onChange: () => {
        resizeTextArea(providerNoteFieldRef);
      },
      onBlur: () => {
        setValue(providerNoteRegisterValue, watchProviderNote?.trim());
        resizeTextArea(providerNoteFieldRef);
      },
    },
  );

  const eligibleActivityId = [
    'activityLinkId',
    'activityAudioId',
    'activityImageId',
    'activityPDFId',
    'activityTextId',
  ].reduce(
    (acc, key) =>
      activity.sendFollowUpActivityInputRaw[key]
        ? activity.sendFollowUpActivityInputRaw[key]
        : acc,
    null,
  );

  const activityCompletionType =
    activityCompletionTypeFromActivityType(watchActivityType);

  return (
    <div
      className={classNames(
        'relative mb-4 w-full rounded-xl border border-neutral-50 bg-white p-4 text-green-150 shadow-none',
        className,
      )}
    >
      {onClickCloseX && (
        <div className="absolute -right-3 -top-3">
          <IconButton
            aria-label="Remove"
            IconComponent={TrashIcon}
            className="hover:rounded-full hover:bg-transparent focus:ring-0"
            iconClassName="h-6 w-6 p-1 rounded-full bg-white border border-red-100 text-red-100 hover:bg-red-100 hover:text-white"
            onClick={onClickCloseX}
          />
        </div>
      )}
      {activity.sendFollowUpActivityInputRaw
        .programAssessmentSignatureUsers && (
        <div className="mb-5 flex w-full flex-row items-start justify-start rounded-md bg-neutral-25 p-4 group-hover:bg-neutral-50">
          <SparklesIcon className="mr-2 h-5 w-5 text-neutral-125" />
          <div className="w-full text-caption text-neutral-125">
            You'll sign this form{' '}
            {requiresSendingProviderSignatureFirst
              ? 'after clicking "Sign & send".'
              : "when it's your turn to sign."}
          </div>
        </div>
      )}
      <ContentPreview
        className="mb-4 rounded-b-none border-b border-neutral-50 pb-4 shadow-none"
        contentType={contentType}
        title={title}
        description={description}
        previewThumbnailImageUrl={previewThumbnailImageUrl}
        disableClick
        toggleActivityOrResourceLabel={false}
        onClick={() => {
          if (previewLinkUrl) {
            window.open(previewLinkUrl, '_blank', 'noopener,noreferrer');
          }
        }}
      />
      <TextAreaGroup
        label={`Activity ${activityIndex + 1}`}
        value={watchProviderNote!}
        placeholder="Add some guidance for this activity"
        rows={1}
        errorMessage={
          errors.activities?.[activityIndex]?.sendFollowUpActivityInputRaw
            ?.providerNote?.message
        }
        labelHidden
        inputSize="small"
        className={classNames(
          'focused:border-green-50 mb-2 h-auto w-full overflow-hidden',
        )}
        containerClassName="w-full"
        {...providerNoteRegister}
        ref={(event) => {
          providerNoteRef(event);
          providerNoteFieldRef.current = event;
        }}
      />
      {Boolean(eligibleActivityId) &&
        watchActivityUseType === ActivityUseType.OneTimeUse && (
          <div className="mt-2 text-small-caption">
            For one-time use with this client only. Does not save to your
            Library.
          </div>
        )}
      {Boolean(activity.sendFollowUpActivityInputRaw.activityPDFId) &&
        Boolean(activityCompletionType) && (
          <div className="mt-4 flex items-center">
            <SparklesIcon className="mr-2 h-6 w-6 flex-shrink-0 text-neutral-125" />
            <div className="text-caption">
              <span
                className="cursor-pointer font-medium underline"
                onClick={() => {
                  onClickModifyFileSettings?.(
                    activity.sendFollowUpActivityInputRaw.activityPDFId,
                  );
                }}
              >
                Current file settings:
              </span>
              {'  '}
              {activityFileCompletionTypeDisplay(activityCompletionType)}
            </div>
          </div>
        )}
    </div>
  );
};

export default ActivityCreationCard;

import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import {
  AssessmentSchemaType,
  useCompletedPdfSubmissionsQuery,
  LatestCompletedProgramAssessmentsQuery,
  useLatestCompletedProgramAssessmentsQuery,
  ActivityType,
  useProviderProgramMediaQuery,
  useUploadProviderProgramMediaMutation,
  ProviderProgramMediaDataFragment,
  useDeleteProviderProgramMediaMutation,
} from '../../../../generated/graphql';

import Spinner from '../../../svgs/Spinner';

import { getTimeStamp } from '../../../lib/time';
import { ContentType } from '../../../lib/followUp';
import { defaultTransitionProps } from '../../../lib/animation';

import ContentPreview from '../../../components/ContentPreview';

import { usePatientProfileContext } from '..';
import AssessmentCompletedSlideover from '../../../components/AssessmentCompletedSlideover';

import LatestAssessmentCards from './LatestAssessmentCards';
import AssessmentsCompletedSlideover from './AssessmentsCompletedSlideover';
import EmptyProfileTab from '../../../components/EmptyProfileTab';
import IllustrationNote from '../../../svgs/IllustrationNote';
import Button from '../../../components/Button';
import {
  acceptedDocumentFileExtensions,
  MAX_UPLOAD_SIZE_BYTES,
} from '../../../lib/media';
import { DocumentIcon, UploadIcon } from '@heroicons/react/outline';
import DocumentNavButton from './DocumentNavButton';
import { customToast } from '../../../components/ToastAlert/customToast';
import NameDocumentModal from './NameDocumentModal';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';

const Documents = () => {
  const params = useParams();
  const programId = params.programId || '';

  const { patient } = usePatientProfileContext();
  const navigate = useNavigate();

  const [
    uploadProviderProgramMedia,
    { loading: uploadProviderProgramMediaLoading },
  ] = useUploadProviderProgramMediaMutation();
  const [
    deleteProviderProgramMedia,
    { loading: deleteProviderProgramMediaLoading },
  ] = useDeleteProviderProgramMediaMutation();

  const {
    data: latestCompletedProgramAssessmentsData,
    error: latestCompletedProgramAssessmentsError,
    loading: latestCompletedProgramAssessmentsLoading,
  } = useLatestCompletedProgramAssessmentsQuery({
    variables: {
      programId,
    },
    skip: !programId,
  });

  const {
    data: completedPdfSubmissionsData,
    error: completedPdfSubmissionsError,
    loading: completedPdfSubmissionsLoading,
  } = useCompletedPdfSubmissionsQuery({
    variables: {
      programId,
    },
    skip: !programId,
  });

  const {
    data: providerProgramMediaData,
    error: providerProgramMediaError,
    loading: providerProgramMediaLoading,
    refetch: refetchProviderProgramMedia,
  } = useProviderProgramMediaQuery({
    variables: {
      programId,
    },
    skip: !programId,
  });

  const latestCompletedProgramAssessments =
    latestCompletedProgramAssessmentsData?.latestCompletedProgramAssessments ??
    [];

  const latestCompletedStandardProgramAssessments =
    latestCompletedProgramAssessments.filter(
      (programAssessment) =>
        programAssessment.assessment.schemaType !== AssessmentSchemaType.Custom,
    );
  const latestCompletedFormProgramAssessments =
    latestCompletedProgramAssessments.filter(
      (programAssessment) =>
        programAssessment.assessment.schemaType === AssessmentSchemaType.Custom,
    );

  const completedPdfSubmissions =
    completedPdfSubmissionsData?.completedPdfSubmissions ?? [];

  const fileSubmissions = completedPdfSubmissions.filter(
    (pdfSubmission) =>
      pdfSubmission.programActivity?.activityType === ActivityType.PdfForm,
  );
  const signedSubmissions = completedPdfSubmissions.filter(
    (pdfSubmission) =>
      pdfSubmission.programActivity?.activityType === ActivityType.Esign,
  );

  const providerProgramMedia =
    providerProgramMediaData?.patient?.providerMedia ?? [];

  const queriesLoading =
    latestCompletedProgramAssessmentsLoading ||
    completedPdfSubmissionsLoading ||
    providerProgramMediaLoading;

  // Both queries have to be loaded with no errors before showing the page
  const shouldShowPage =
    !queriesLoading &&
    !latestCompletedProgramAssessmentsError &&
    !completedPdfSubmissionsError &&
    !providerProgramMediaError;

  const hasResults =
    latestCompletedProgramAssessments.length > 0 ||
    completedPdfSubmissions.length > 0 ||
    providerProgramMedia.length > 0;

  const [
    isAssessmentsCompletedSlideoverOpen,
    setIsAssessmentsCompletedSlideoverOpen,
  ] = useState(false);
  const [
    isAssessmentCompletedSlideoverOpen,
    setIsAssessmentCompletedSlideoverOpen,
  ] = useState(false);

  const [isNameDocumentModalOpen, setIsNameDocumentModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const [selectedAssessment, setSelectedAssessment] = useState<
    | LatestCompletedProgramAssessmentsQuery['latestCompletedProgramAssessments'][number]['assessment']
    | undefined
  >();
  const [selectedProgramAssessmentId, setSelectedProgramAssessmentId] =
    useState<string | undefined>();
  const [selectedDocument, setSelectedDocument] =
    useState<ProviderProgramMediaDataFragment | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleUploadFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file size
    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      customToast.warning(
        'File is too large. Please upload a file smaller than 100MB.',
      );
      return;
    }

    setSelectedFile(file);
    setIsNameDocumentModalOpen(true);

    // Reset the file input after processing so the same file can be uploaded again
    event.target.value = '';
  };

  const handleUploadDocument = async (documentName: string) => {
    if (!selectedFile) {
      customToast.error('No file selected.');
      return;
    }

    try {
      await uploadProviderProgramMedia({
        variables: {
          input: {
            programId,
            file: selectedFile,
            name: documentName,
          },
        },
      });
      await refetchProviderProgramMedia();
      setSelectedNavItem('uploaded-documents');
      customToast.success('Document uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      customToast.error('Error uploading document.');
    }
  };

  const handleDeleteDocument = async () => {
    if (!selectedDocument) {
      customToast.error('No document selected.');
      return;
    }

    try {
      await deleteProviderProgramMedia({
        variables: {
          providerProgramMediaId: selectedDocument.id,
        },
      });
      await refetchProviderProgramMedia();
      customToast.success('Document deleted successfully.');
    } catch (error) {
      console.error('Error deleting document:', error);
      customToast.error('Error deleting document.');
    }
  };

  const handleSendMailClick = () => {
    navigate(`/${patient?.programInstanceId}/follow-ups/new`);
  };

  const navItems = [
    {
      id: 'all',
      title: 'All documents',
      icon: DocumentIcon,
      emptyState: {
        title: 'No forms, assessments or documents yet',
        description: `Any forms or assessments you send to ${patient?.firstName} that they complete will show up here. You can also upload external documents to keep everything in one place.`,
        buttonTitle: `Send mail to ${patient?.firstName}`,
        onClickButton: handleSendMailClick,
        ButtonComponent: (
          <Button
            theme="secondary"
            size="small"
            className="mt-4 px-6"
            title="Upload a document"
            onClick={handleUploadFileClick}
            disabled={uploadProviderProgramMediaLoading}
          />
        ),
      },
    },
    {
      id: 'completed-forms',
      title: 'Completed forms',
      emptyState: {
        title: 'No forms yet',
        description: `Any forms you send to ${patient?.firstName} that they complete will show up here.`,
        buttonTitle: `Send mail to ${patient?.firstName}`,
        onClickButton: handleSendMailClick,
      },
    },
    {
      id: 'assessments',
      title: 'Assessments',
      emptyState: {
        title: 'No assessments yet',
        description: `Any assessments you send to ${patient?.firstName} that they complete will show up here.`,
        buttonTitle: `Send mail to ${patient?.firstName}`,
        onClickButton: handleSendMailClick,
      },
    },
    {
      id: 'signed-submissions',
      title: 'Signed submissions',
      emptyState: {
        title: 'No signed submissions yet',
        description: `Any E-sign PDFs you send to ${patient?.firstName} to sign will show up here.`,
        buttonTitle: `Send mail to ${patient?.firstName}`,
        onClickButton: handleSendMailClick,
      },
    },
    {
      id: 'file-submissions',
      title: 'File submissions',
      emptyState: {
        title: 'No file submissions yet',
        description: `Any files you send to ${patient?.firstName} to fill out & return will show up here.`,
        buttonTitle: `Send mail to ${patient?.firstName}`,
        onClickButton: handleSendMailClick,
      },
    },
    {
      id: 'uploaded-documents',
      title: 'Uploaded documents',
      emptyState: {
        title: 'No uploaded documents yet',
        description: `Upload ${patient?.firstName}’s external documents here to keep everything in one place. These will only be visible to you and your team.`,
        buttonTitle: 'Upload a document',
        onClickButton: handleUploadFileClick,
      },
    },
  ];

  const [selectedNavItem, setSelectedNavItem] = useState(navItems[0].id);

  const renderEmptyState = (navItem: typeof navItems[number]) => (
    <EmptyProfileTab
      IconComponent={IllustrationNote}
      iconClassName={'text-green-100'}
      titleText={navItem.emptyState.title}
      subTitleText={navItem.emptyState.description}
      buttonTitle={navItem.emptyState.buttonTitle}
      onClickButton={navItem.emptyState.onClickButton}
      ButtonComponent={navItem.emptyState.ButtonComponent}
    />
  );

  const renderContent = () => {
    const navItem = navItems.find((item) => item.id === selectedNavItem);
    if (!navItem) return null;

    switch (navItem.id) {
      case 'all':
        if (!hasResults) {
          return renderEmptyState(navItem);
        }
        return (
          <>
            {renderCompletedForms(true)}
            {renderAssessments(true)}
            {renderSignedSubmissions(true)}
            {renderFileSubmissions(true)}
            {renderUploadedDocuments(true)}
          </>
        );
      case 'completed-forms':
        return latestCompletedFormProgramAssessments.length
          ? renderCompletedForms(true)
          : renderEmptyState(navItem);
      case 'assessments':
        return latestCompletedStandardProgramAssessments.length
          ? renderAssessments(true)
          : renderEmptyState(navItem);
      case 'signed-submissions':
        return signedSubmissions.length
          ? renderSignedSubmissions(true)
          : renderEmptyState(navItem);
      case 'file-submissions':
        return fileSubmissions.length
          ? renderFileSubmissions(true)
          : renderEmptyState(navItem);
      case 'uploaded-documents':
        return providerProgramMedia.length
          ? renderUploadedDocuments(true)
          : renderEmptyState(navItem);
      default:
        return null;
    }
  };

  const renderCompletedForms = (showTitle = false) => {
    if (latestCompletedFormProgramAssessments.length === 0) return null;

    return (
      <div>
        {showTitle && (
          <div className="text-category font-medium text-green-150">
            Completed forms
          </div>
        )}
        <LatestAssessmentCards
          latestCompletedProgramAssessments={
            latestCompletedFormProgramAssessments
          }
          setSelectedAssessment={setSelectedAssessment}
          setSelectedProgramAssessmentId={setSelectedProgramAssessmentId}
          setIsAssessmentsCompletedSlideoverOpen={
            setIsAssessmentsCompletedSlideoverOpen
          }
          setIsAssessmentCompletedSlideoverOpen={
            setIsAssessmentCompletedSlideoverOpen
          }
        />
      </div>
    );
  };

  const renderAssessments = (showTitle = false) => {
    if (latestCompletedStandardProgramAssessments.length === 0) return null;

    return (
      <div>
        {showTitle && (
          <div className="text-category font-medium text-green-150">
            Assessments
          </div>
        )}
        <LatestAssessmentCards
          latestCompletedProgramAssessments={
            latestCompletedStandardProgramAssessments
          }
          setSelectedAssessment={setSelectedAssessment}
          setSelectedProgramAssessmentId={setSelectedProgramAssessmentId}
          setIsAssessmentsCompletedSlideoverOpen={
            setIsAssessmentsCompletedSlideoverOpen
          }
          setIsAssessmentCompletedSlideoverOpen={
            setIsAssessmentCompletedSlideoverOpen
          }
        />
      </div>
    );
  };

  const renderSignedSubmissions = (showTitle = false) => {
    if (signedSubmissions.length === 0) return null;

    return (
      <div>
        {showTitle && (
          <div className="text-category font-medium text-green-150">
            Signed submissions
          </div>
        )}
        <div className="mt-6 flex w-full flex-wrap gap-7">
          {signedSubmissions?.map((pdfSubmission, i) => (
            <ContentPreview
              key={`signedSubmissionContentPreview_${i}`}
              contentType={ContentType.Esign}
              className="max-w-[284px] !p-3"
              title={pdfSubmission.programActivity.name}
              description={`Signed ${getTimeStamp(
                pdfSubmission.signedAt,
                false,
              )}`}
              onClick={() => {
                window.open(
                  pdfSubmission?.media?.url,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderFileSubmissions = (showTitle = false) => {
    if (fileSubmissions.length === 0) return null;

    return (
      <div>
        {showTitle && (
          <div className="text-category font-medium text-green-150">
            File submissions
          </div>
        )}
        <div className="mt-6 flex w-full flex-wrap gap-7">
          {fileSubmissions?.map((pdfSubmission, i) => (
            <ContentPreview
              key={`fileSubmissionContentPreview_${i}`}
              contentType={ContentType.PdfForm}
              className="max-w-[284px] !p-3"
              title={pdfSubmission.programActivity.name}
              description={`Completed ${getTimeStamp(
                pdfSubmission.programActivity?.completedAt,
                false,
              )}`}
              onClick={() => {
                window.open(
                  pdfSubmission?.media?.url,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderUploadedDocuments = (showTitle = false) => {
    if (providerProgramMedia.length === 0) return null;

    return (
      <div>
        {showTitle && (
          <div className="text-category font-medium text-green-150">
            Uploaded documents
          </div>
        )}
        <div className="mt-6 flex w-full flex-wrap gap-7">
          {providerProgramMedia?.map((providerProgramMedia, i) => (
            <ContentPreview
              key={`uploadedDocumentContentPreview_${i}`}
              contentType={ContentType.PDF}
              className="max-w-[284px] !p-3"
              title={providerProgramMedia.name}
              description={`Uploaded ${getTimeStamp(
                providerProgramMedia.createdAt,
                false,
              )}`}
              onClick={() => {
                window.open(
                  providerProgramMedia.media.url,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
              onClickCloseX={() => {
                setSelectedDocument(providerProgramMedia);
                setIsConfirmDeleteModalOpen(true);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition
        show={shouldShowPage}
        {...defaultTransitionProps}
        className="flex h-full w-full flex-grow flex-row items-start"
      >
        <div className="flex h-full w-[13.125rem] shrink-0 grow-0 flex-col border-r border-neutral-75">
          <div className="border-b border-neutral-75 py-4">
            <Button
              size="small"
              IconComponent={
                uploadProviderProgramMediaLoading ? Spinner : UploadIcon
              }
              title="Upload a document"
              iconClassName="h-5 w-5 shrink-0"
              onClick={handleUploadFileClick}
              disabled={uploadProviderProgramMediaLoading}
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              accept={acceptedDocumentFileExtensions.join(',')}
            />
          </div>
          <div className="flex h-full w-full flex-col gap-y-2 overflow-y-auto overscroll-y-contain py-4 pr-4">
            {navItems.map((item) => (
              <DocumentNavButton
                key={item.id}
                title={item.title}
                isSelected={selectedNavItem === item.id}
                onClick={() => setSelectedNavItem(item.id)}
                IconComponent={item.icon}
              />
            ))}
          </div>
        </div>
        <div className="flex h-full w-full flex-col gap-y-10 overflow-y-auto px-5 pb-10 pt-5">
          {renderContent()}
        </div>
      </Transition>

      <AssessmentsCompletedSlideover
        onClose={() => setIsAssessmentsCompletedSlideoverOpen(false)}
        isOpen={isAssessmentsCompletedSlideoverOpen}
        patientFirstName={`${patient?.firstName}`}
        programId={patient?.programInstanceId}
        selectedAssessment={selectedAssessment}
      />

      <AssessmentCompletedSlideover
        isOpen={isAssessmentCompletedSlideoverOpen}
        onClose={() => setIsAssessmentCompletedSlideoverOpen(false)}
        programAssessmentId={selectedProgramAssessmentId ?? ''}
        patientFirstName={patient?.firstName ?? ''}
      />

      <NameDocumentModal
        isOpen={isNameDocumentModalOpen}
        setClosed={() => setIsNameDocumentModalOpen(false)}
        documentName={selectedFile?.name}
        handleSaveDocument={handleUploadDocument}
      />

      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        setClosed={() => {
          setIsConfirmDeleteModalOpen(false);
          setSelectedDocument(null);
        }}
        title="Are you sure you want to delete this document?"
        actionButtonTitle="Delete"
        performDelete={handleDeleteDocument}
        fetching={deleteProviderProgramMediaLoading}
      />

      {!shouldShowPage && <Spinner className="mx-auto mt-8" />}
    </>
  );
};

export default Documents;

import debounce from 'lodash.debounce';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { UseFieldArrayPrepend } from 'react-hook-form';
import toast from 'react-hot-toast';

import ModalDialog from '../../ModalDialog';
import ToastAlert from '../../ToastAlert';

import {
  Exact,
  ProviderCollection,
  ActivityDataFragment,
  ProviderActivitiesQuery,
  useProviderActivitiesQuery,
  useProviderCollectionsQuery,
} from '../../../../generated/graphql';

import { DEFAULT_TRANSITION_DURATION } from '../../../lib/animation';
import { ProviderActivityType } from '../../../types/activity';
import { ColumnOrder } from '../../../types/tables';

import { MODAL_TRANSITION_DURATION } from '../../Modal';

import AllResourcesButton from '../../Library/AllResourcesButton';
import LibraryTable from '../../Library/LibraryTable';
import LibraryTableContainer from '../../Library/LibraryTableContainer';
import ProviderActivitySlideover from '../../Library/ProviderActivitySlideover';
import ProviderCollectionsColumn from '../../Library/ProviderCollectionsColumn';
import ProviderCollectionsList from '../../Library/ProviderCollectionsList';
import SearchAndFilters from '../../SearchAndFilters';
import {
  doesActivityMeetFilters,
  doesCollectionMeetFilters,
  getSelectedProviderActivitiesList,
  sortActivities,
  sortCollections,
  sortOptions,
} from '../../Library/helpers';

import { ContentType, FollowUpForm } from '../../../lib/followUp';
import {
  ProviderActivityObject,
  formatProviderActivityForFollowUp,
} from '../../../lib/providerActivity';

import Button from '../../Button';
import AddOrEditTextModal from '../AddOrEditTextModal';
import { ApolloQueryResult } from '@apollo/client';

type AddResourceModalProps = {
  isModalOpen: boolean;
  setClosed: () => void;
  contentTypeFilterLock?: ContentType;
  restrictSingleSelection?: boolean;
  onAddProviderActivities?: (
    providerActivities: ActivityDataFragment[],
  ) => void;
  prependToFollowUp?: UseFieldArrayPrepend<FollowUpForm, 'activities'>;
  ignoreMultiSignatureForms?: boolean;
};

export interface AddResourceModalRef {
  refetchProviderActivities: (
    variables?: Partial<
      Exact<{
        [key: string]: never;
      }>
    >,
  ) => Promise<ApolloQueryResult<ProviderActivitiesQuery>>;
}

const AddResourceModal: React.ForwardRefExoticComponent<
  AddResourceModalProps & React.RefAttributes<AddResourceModalRef>
> = forwardRef<AddResourceModalRef, AddResourceModalProps>(
  (
    {
      isModalOpen,
      setClosed,
      contentTypeFilterLock,
      restrictSingleSelection,
      onAddProviderActivities,
      prependToFollowUp,
      ignoreMultiSignatureForms,
    },
    ref,
  ) => {
    const [selectedProviderActivity, setSelectedProviderActivity] = useState<
      ActivityDataFragment | undefined
    >();

    const {
      data: providerActivitiesData,
      error: providerActivitiesError,
      loading: providerActivitiesLoading,
      refetch: refetchProviderActivities,
    } = useProviderActivitiesQuery();

    // Expose library refetch function so that it can be updated from the parent component
    // when resources are modified
    useImperativeHandle(ref, () => ({
      refetchProviderActivities,
    }));

    const providerActivities = providerActivitiesData?.providerActivities ?? [];

    const { data: providerCollectionsData, error: providerCollectionsError } =
      useProviderCollectionsQuery();

    const providerCollections = providerCollectionsData?.providerCollections;

    const [selectedProviderCollection, setSelectedProviderCollection] =
      useState<ProviderCollection | undefined>();

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermValue, setSearchTermValue] = useState('');

    const debouncedSearch = useCallback(
      debounce(
        (e) => setSearchTerm(e.target.value.toLowerCase().trim()),
        DEFAULT_TRANSITION_DURATION,
      ),
      [],
    );

    const [contentTypeFilter, setContentTypeFilter] =
      useState<ContentType | null>(contentTypeFilterLock ?? null);

    const hasContentTypeFilter = contentTypeFilter !== null;

    const [activityOrder, setActivityOrder] = useState<ColumnOrder>(
      sortOptions.newestCreated,
    );

    const providerActivitiesList = sortActivities(
      providerActivities?.filter((providerActivity) => {
        return doesActivityMeetFilters(providerActivity, {
          contentTypeFilter,
          searchTerm,
          selectedProviderCollection,
          ignoreMultiSignatureForms,
        });
      }),
      activityOrder !== null ? activityOrder : sortOptions.newestCreated,
    );

    const providerCollectionHasResources = Boolean(
      selectedProviderCollection && providerActivitiesList.length > 0,
    );

    const sortedProviderCollections = sortCollections(
      providerCollections?.filter((providerCollection) =>
        doesCollectionMeetFilters(providerCollection, {
          contentTypeFilter,
          searchTerm,
          selectedProviderCollection,
        }),
      ),
      activityOrder !== null ? activityOrder : sortOptions.newestCreated,
    );

    useEffect(() => {
      if (providerActivitiesError) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Unable to fetch library items."
            level="error"
          />
        ));
      }
    }, [providerActivitiesError]);

    useEffect(() => {
      if (providerCollectionsError) {
        toast.custom(({ visible }) => (
          <ToastAlert
            isVisible={visible}
            message="Unable to fetch library collections."
            level="error"
          />
        ));
      }
    }, [providerCollectionsError]);

    const clearSearchTerm = () => {
      setSearchTermValue('');
      setSearchTerm('');
    };

    const showEmptyTableFilterState = Boolean(
      !providerActivitiesLoading &&
        providerActivitiesList !== undefined &&
        providerActivitiesList.length === 0 &&
        sortedProviderCollections?.length === 0,
    );

    const clearFilterTerm = () => setContentTypeFilter(null);

    const clearSearchAndFilters = () => {
      clearSearchTerm();
      if (!contentTypeFilterLock) {
        // When the content type filter is locked, nothing should
        // clear it so that the library stays globally filtered
        clearFilterTerm();
      }
    };

    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTermValue(e.target.value);
      debouncedSearch(e);
    };

    const [
      isProviderActivitySlideoverOpen,
      setIsProviderActivitySlideoverOpen,
    ] = useState(false);
    const [isAddOrEditTextModalOpen, setIsAddOrEditTextModalOpen] =
      useState(false);

    const closeAndResetAttachResourceModal = async () => {
      setClosed();
      setTimeout(() => {
        // Reset state
        setSelectedProviderActivitiesObject({});
        setSelectedProviderCollection(undefined);
      }, MODAL_TRANSITION_DURATION);
    };

    const [
      selectedProviderActivitiesObject,
      setSelectedProviderActivitiesObject,
    ] = useState<ProviderActivityObject>({});

    const selectedProviderActivitiesList = getSelectedProviderActivitiesList(
      selectedProviderActivitiesObject,
    );

    const clearSelectedProviderActivities = () =>
      setSelectedProviderActivitiesObject({});

    const saveProviderActivitiesToFollowUp = (
      addWholeCollection = false,
      providerActivitiesToAddOverride?: ActivityDataFragment[],
    ) => {
      const providerActivitiesToAdd =
        providerActivitiesToAddOverride ??
        (!addWholeCollection
          ? selectedProviderActivitiesList
          : providerActivitiesList);

      const formattedProviderActivities = providerActivitiesToAdd?.map(
        (providerActivity, index) =>
          formatProviderActivityForFollowUp(providerActivity, index),
      );

      formattedProviderActivities &&
        prependToFollowUp?.(formattedProviderActivities);

      onAddProviderActivities?.(providerActivitiesToAdd);

      closeAndResetAttachResourceModal();
    };

    const sendProviderActivitiesInFollowUp = (
      providerActivitiesToAdd: ActivityDataFragment[],
    ) => {
      saveProviderActivitiesToFollowUp(false, providerActivitiesToAdd);
    };

    // Whenever the search / filter / sidebar state changes, deselect all
    useEffect(() => {
      clearSelectedProviderActivities();
    }, [searchTerm, contentTypeFilter, selectedProviderCollection]);

    return (
      <>
        <ModalDialog
          title="Attach a resource"
          isOpen={isModalOpen}
          setClosed={closeAndResetAttachResourceModal}
          isBlocking={
            isProviderActivitySlideoverOpen || isAddOrEditTextModalOpen
          }
          leftFooter={
            <>
              {Boolean(selectedProviderActivitiesList.length) ? (
                <div className="text-caption font-medium text-neutral-125">{`${
                  selectedProviderActivitiesList.length
                } resource${
                  selectedProviderActivitiesList.length > 1 ? 's' : ''
                } selected`}</div>
              ) : (
                <div />
              )}
            </>
          }
          rightFooter={
            <div className="flex flex-row items-center justify-end">
              {selectedProviderCollection &&
                providerCollectionHasResources &&
                !restrictSingleSelection && (
                  <Button
                    title="Attach this collection"
                    theme="secondary"
                    className="mr-4"
                    onClick={() => saveProviderActivitiesToFollowUp(true)}
                  />
                )}
              <Button
                title="Attach selected resources"
                onClick={() => saveProviderActivitiesToFollowUp()}
                disabled={!selectedProviderActivitiesList.length}
              />
            </div>
          }
          bodyClassName="flex w-full flex-col p-0 pl-6 pt-6"
        >
          <div className="mb-6 flex h-[48px] w-full flex-row items-center justify-between">
            <SearchAndFilters
              searchTermValue={searchTermValue}
              clearSearchTerm={clearSearchTerm}
              clearFilterTerm={clearFilterTerm}
              onChangeSearch={onChangeSearch}
              contentTypeFilter={contentTypeFilter}
              setContentTypeFilter={setContentTypeFilter}
              contentTypeFilterLock={contentTypeFilterLock}
            />
          </div>
          <LibraryTableContainer containerClassName="max-h-[65vh] min-h-[65vh]">
            <ProviderCollectionsColumn>
              <AllResourcesButton
                selectedProviderCollection={selectedProviderCollection}
                setSelectedProviderCollection={setSelectedProviderCollection}
                communityResourcesSelected={false}
                followUpMode
              />
              <ProviderCollectionsList
                providerCollections={providerCollections}
                selectedProviderCollection={selectedProviderCollection}
                setSelectedProviderCollection={setSelectedProviderCollection}
                clearSearchAndFilters={clearSearchAndFilters}
              />
            </ProviderCollectionsColumn>
            <LibraryTable
              followUpMode
              restrictSingleSelection={restrictSingleSelection}
              activityOrder={activityOrder}
              providerActivities={providerActivitiesList}
              selectedProviderCollection={selectedProviderCollection}
              setActivityOrder={setActivityOrder}
              setSelectedProviderActivity={setSelectedProviderActivity}
              setSelectedProviderCollection={setSelectedProviderCollection}
              refetchProviderActivities={refetchProviderActivities}
              showEmptyTableFilterState={showEmptyTableFilterState}
              hasEmptyProviderCollection={Boolean(
                selectedProviderCollection &&
                  providerActivitiesList?.length === 0 &&
                  !hasContentTypeFilter &&
                  !searchTerm,
              )}
              clearSearchAndFilters={clearSearchAndFilters}
              sortedProviderCollections={sortedProviderCollections}
              allProviderCollections={providerCollections}
              setIsProviderActivitySlideoverOpen={
                setIsProviderActivitySlideoverOpen
              }
              openAddOrEditTextModal={() => setIsAddOrEditTextModalOpen(true)}
              setSelectedProviderActivitiesObject={
                setSelectedProviderActivitiesObject
              }
              selectedProviderActivitiesObject={
                selectedProviderActivitiesObject
              }
              selectedProviderActivitiesList={selectedProviderActivitiesList}
              contentTypeFilter={contentTypeFilter}
              searchTerm={searchTerm}
              allProviderActivities={providerActivities}
              sendProviderActivitiesInFollowUp={
                sendProviderActivitiesInFollowUp
              }
            />
          </LibraryTableContainer>
        </ModalDialog>
        <ProviderActivitySlideover
          isOpen={isProviderActivitySlideoverOpen}
          onClose={() => setIsProviderActivitySlideoverOpen(false)}
          selectedProviderActivity={selectedProviderActivity}
          followUpMode
        />
        <AddOrEditTextModal
          isModalOpen={isAddOrEditTextModalOpen}
          setClosed={() => setIsAddOrEditTextModalOpen(false)}
          clearModalData={() => setSelectedProviderActivity(undefined)}
          viewOnly
          selectedActivityText={
            selectedProviderActivity?.__typename === ProviderActivityType.Text
              ? selectedProviderActivity
              : undefined
          }
        />
      </>
    );
  },
);

export default AddResourceModal;

import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  CommentDataFragment,
  useMarkCommentsReadMutation,
  useMePatientCommentsLazyQuery,
} from '../../../../generated/graphql';
import ToastAlert from '../../../components/ToastAlert';
import Spinner from '../../../svgs/Spinner';
import PatientChat from '../../../components/Comments/PatientChat';
import AppStoreLinks from '../../../components/AppStoreLinks';
import Logo from '../../../svgs/Logo';
import DownloadAppBanner from '../../../components/PatientApp/DownloadAppBanner';

const PatientMessages = () => {
  const [
    patientComemntsQuery,
    {
      data: patientCommentsData,
      error: patientCommentsError,
      loading: patientCommentsLoading,
    },
  ] = useMePatientCommentsLazyQuery();

  const [patientComments, setPatientComments] = useState<CommentDataFragment[]>(
    [],
  );
  const [lastCommentDatetime, setLastCommentDatetime] = useState<Date>(
    new Date(),
  );
  const programId = patientCommentsData?.mePatient?.programInstanceId;

  const endOfMessagesFeedRef = useRef<boolean>(false);
  const [firstChunkFetched, setFirstChunkFetched] = useState<boolean>(false);

  const getNextChunkOfComments = async () => {
    if (endOfMessagesFeedRef.current || patientCommentsLoading) {
      return;
    }
    const { data } = await patientComemntsQuery({
      variables: {
        before: lastCommentDatetime.toISOString(),
      },
    });
    setFirstChunkFetched(true);
    const newComments = data?.mePatient?.comments ?? [];
    if (newComments.length > 0) {
      // If it is the first render, then just set the comments outright instead of prepending.
      // This helps prevent a double prepend on initial load while in React.StrictMode
      // in development due to the double render.
      if (patientComments.length === 0) {
        setPatientComments(newComments);
      } else {
        setPatientComments((prevComments) => [...newComments, ...prevComments]);
      }
      setLastCommentDatetime(new Date(newComments[0].createdAt));
    } else {
      endOfMessagesFeedRef.current = true;
    }
  };

  useEffect(() => {
    getNextChunkOfComments();
  }, []);

  const onCommentAdded = async (newComment?: CommentDataFragment) => {
    if (!newComment) {
      return;
    }
    setPatientComments((prevComments) => [...prevComments, newComment]);
  };

  const onCommentDeleted = async (commentId?: string) => {
    if (!commentId) {
      return;
    }
    setPatientComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== commentId),
    );
  };

  useEffect(() => {
    if (patientCommentsError) {
      toast.custom(({ visible }) => (
        <ToastAlert
          isVisible={visible}
          message="Unable to fetch messages."
          level="error"
        />
      ));
    }
  }, [patientCommentsError]);

  const [markCommentsReadMutation] = useMarkCommentsReadMutation();

  useEffect(() => {
    const markFollowUpCommentsRead = async () => {
      if (programId) {
        await markCommentsReadMutation({
          variables: {
            input: {
              programId,
            },
          },
        });
      }
    };

    markFollowUpCommentsRead();
  }, [programId]);

  return (
    <div className="flex w-full flex-row sm:px-16">
      {!firstChunkFetched ? (
        <Spinner className="mx-auto mt-8" />
      ) : (
        <div className="w-full">
          <DownloadAppBanner className="sm:hidden" />
          <div className="my-8 flex w-full flex-col xl:flex-row">
            <div>
              <div className="mb-4 px-8 font-serif text-subtitle-small font-light sm:px-0 md:text-subtitle">
                Chat
              </div>
              <div className="border-neutral-75 xl:mr-6 xl:w-[800px] xl:border-l xl:border-r">
                <PatientChat
                  comments={patientComments}
                  chatHeader={null}
                  programId={programId}
                  onCommentAdded={onCommentAdded}
                  onCommentDeleted={onCommentDeleted}
                  conversationContainerClassName="max-h-[calc(100vh-330px)] sm:max-h-[calc(50vh)]"
                  getNextChunkOfComments={getNextChunkOfComments}
                  areCommentsLoading={patientCommentsLoading}
                />
              </div>
            </div>
            <div className="mt-6 hidden w-full flex-row justify-center px-8 xl:flex xl:px-0">
              <div className="flex flex-col items-center rounded-lg border border-neutral-75 p-8 xl:w-[400px] xl:border-0 xl:p-0">
                <Logo className="h-[70px] w-[70px]" />
                <div className="mt-6 text-center font-serif text-title-small">
                  Prefer to use an app?
                </div>
                <div className="my-5 text-center text-paragraph">
                  The Homecoming Companion app gives you easy access to all of
                  the notes, actions, and resources you've been sent. It's the
                  best way to stay in touch between sessions.
                </div>
                <AppStoreLinks centered={true} fullWidth={true} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientMessages;
